@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$fontFamily:'Montserrat' , sans-serif;
.App-header{
  display:block !important;
}
.App-footer{
  display:block !important;
}

#home_page_carousel_section{
  padding-left: 0;
  padding-right:0;
}
.home_page_about_section{
  background: #F2F2F2;
  box-shadow: 0px 0px 12px 5px #E5E5E5;
  margin-top: 100px;
  @media screen and (max-width: 600px){
    background: #fff;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 0px 0px 0px 0px #E5E5E5;
    margin-top: 70px !important;
  }
  .mobile-size-bgc{
    @media screen and (max-width: 600px){
      background: #F2F2F2;
      box-shadow: 0px 0px 12px 5px #E5E5E5;

    }
  }
  @media screen and (max-width: 600px){
    margin-top: 30px;
  }
  .home_about_text{
    padding-left: 80.26px;
    padding-right: 80.26px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width:992px) {
      padding-left: 20.26px;
      padding-right: 20.26px;
    }
    @media screen and (max-width:778px) {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
    @media screen and (max-width:576px) {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 0px;
      padding-right: 20px;
    }

    .home_about_title{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #494949;
      @media screen and (max-width: 600px){
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #494949;
      }
    }
    .home_about_desc{
      font-family:$fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.015em;
      color: #494949;
      text-align: justify;
      @media screen and (max-width: 600px){
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.015em;
        color: #494949;
      }
    }
  }
  .right_content{
    padding-left: 0;
    padding-right: 0;
    img{
      @media screen and (max-width:992px) {
        //height: 100%;
        //opacity: 0;
      }
      @media screen and (max-width:778px) {
        height: auto;
      }
    }
  }

}
.flex_end_small_contents{
  margin-top: 40px;
  padding-left: 0;
  padding-right: 0;
  @media screen and (max-width: 600px){
    padding-left: 15px;
    padding-right: 15px;
  }
  .icon_contents{
    display: flex;
    background: #00386A;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px){
      padding-left: 15px;
      padding-right: 15px;
    }
    .for_circle_icon{
      border-radius: 50%;
      border: 1px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 76.09px;
      height: 76.09px;
    }
    .for_icon_text{
      width: 229px;
      padding-left: 20px;
      //padding-right: 20px;
      padding-top: 20px;
      //padding-bottom: 20px;
      .for_icon_title{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        @media screen and (max-width: 600px) {
          font-family:$fontFamily;
          font-style: normal;
          font-weight: 800;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
        }
      }
      .for_icon_desc{
        font-family:$fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        //text-align: justify;
        @media screen and (max-width: 600px) {
          font-family:$fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
        }
      }
    }
  }
  .icon_contents_second{
    background: #0F62AC;
  }
}
.section_for_brands_background{
  background: rgba(242, 242, 242, 1);
  padding-top: 80px;
  padding-bottom: 80px;
  margin-top: 120px;
  .mobile-size-bgc{
    @media screen and (max-width: 600px){
      background: #F2F2F2;
      box-shadow: 0px 0px 12px 5px #E5E5E5;
      padding-bottom: 30px;
      padding-top: 40px;
    }
  }
  @media screen and (max-width: 600px){
    background: #fff;
    margin-top: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .section_for_brands{
    @media screen and (max-width: 600px){
      margin-top: 30px;
    }
    .brand_text{
      font-family:$fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.015em;
      text-align: center;
      color: #494949;
      @media screen and (max-width: 600px){
        font-family:$fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #494949;
      }
    }
    .brand_small_text{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #494949;
      margin-top: 30px;
      @media screen and (max-width: 600px){
        font-family:$fontFamily;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.015em;
        color: #494949;
        text-align: center;
        padding: 0px 30px;
      }
    }
    .brand_image_row{
      margin-top: 70px;
      @media screen and (max-width: 600px){
        margin-top: 30px;
      }
      .col_for_brand{
        display: flex;
        justify-content: center;
        @media screen and (max-width: 600px){
          display: flex;
          justify-content: center;
        }
        .for_brand_logo{
          background: #fff;
          width: 243px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          flex-direction: column;
          //border: 1px solid #F2F2F2;
          box-sizing: border-box;
          transition: all .5s linear;
          cursor: pointer;
          overflow: hidden;
          &:hover{
            box-shadow:0px 4px 17px #B8B6B6;
            transition: all .3s linear;
          }

          &:hover .for_brand_logo_text{
            transition: all .3s linear;
            transform: scale(1.03);
          }
          @media screen and (max-width: 992px) {
            width: 230px;
          }
          @media screen and (max-width: 767px) {
            margin-top: 20px;
          }

          .for_brand_logo_img{
            //padding-top: 26px;
            //padding-bottom: 26px;
            transition: all .5s linear;
            height: 142px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              max-height: 70px;
            }
          }
          .for_brand_logo_text{
            background: #0F62AC;
            //border: 2px solid #73B3F2;
            box-sizing: border-box;
            box-shadow: inset 0px 4px 17px rgba(0, 56, 106, 0.15);
            border-radius: 0px;
            padding: 20px 10px 20px 10px;
            position: relative;
            transition: all .3s linear;

            &::after{
              position: absolute;
              content: "";
              clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
              background: #FFFFFF;
              width: 28px;
              height:14px;
              transform: rotate(180deg);
              top: -2px;
              left: 98px;
            }
            .for_brand_logo_title{
              font-family: $fontFamily;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              letter-spacing: -0.015em;
              color: #FFFFFF;
            }
            .for_brand_logo_desc{
              font-family:$fontFamily;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 17px;
              text-align: center;
              letter-spacing: -0.015em;
              color: #FFFFFF;
            }

          }
        }
      }
    }
    #link_for_see_more_small{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 171px;
      height: 43px;
      border: 1px solid #00386A;
      box-sizing: border-box;
      border-radius: 20px;
      font-family:$fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #00386A !important;
      margin: auto;
      margin-top:82px;
      transition: all .3s linear;
      -webkit-transition: all .3s linear;
      -moz-transition: all .3s linear;
      -ms-transition: all .3s linear;
      -o-transition: all .3s linear;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      &:hover{
        background: #73B3F2 !important;
        color: #FFFFFF !important;
      }

    }
  }
}
#section_for_product{
  padding-top:60px;
  position: relative;
  &::after{
    position: absolute;
    content: "";
    bottom:0;
    width: 100%;
    height: 46%;
    left:0;
    background: rgba(242, 242, 242, 1);
    @media screen and (max-width:800px){
      height:0;
    }
    @media screen and (max-width:800px){
      height:50%;
    }
  }
  #row_for_text{
    .for_product_big_text{
      font-family:$fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #494949;
      @media screen and (max-width: 600px){
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.015em;
        color: #494949;
      }
    }
    .for_product_small_text{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 100;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #494949;
      margin-top: 20px;
      @media screen and (max-width: 600px){
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: -0.015em;
        color: #494949;
        padding: 0px 26px;
      }
    }
    .for_product_small_text_none{
      display: none;
      margin-top: 20px;
      font-family: Montserrat arm;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.015em;
      color: rgba(73, 73, 73, 1);
      @media screen and (max-width: 600px){
        display: none;
        font-size: 14px;
      }
    }

  }
  .for_img_row{
    margin-top: 60px;
    .div_for_png{
      overflow: hidden;
      cursor: pointer;
      // padding:0;
      @media screen and (max-width:600px){
        display:flex;
        justify-content:center;
        padding: 0 !important;
        margin-top: 0px !important;
      }
      .for_product_image_hover{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 99999;
        @media screen and (max-width: 600px){
          padding:0;
          width:fit-content;
        }
        .relative_div_img{
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          transition:all .3s linear;
          overflow: hidden;
          img{
            transition:all .3s linear;
            filter: none;
            &:hover{
              transform: scale(1.2);
            }
          }

        }
        .absolute_div_text{
          position: absolute;
          height: auto;
          width: 100%;
          bottom:0;
          background: #00386A;
          box-shadow: -2px 4px 6px #DADADA;
          padding-top: 16px;
          padding-bottom:16px;
          font-family:$fontFamily;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          letter-spacing: -0.015em;
          color: #FFFFFF;
          @media screen and (max-width: 600px){
            font-size: 14px;
          }
        }
        //&:hover .absolute_div_text{
        //    transform: translateY(0);
        //    opacity: 1;
        //}
      }
    }
  }
  .for_see_more_Link{
    display: flex;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    z-index: 9999999999999;
    @media screen and (max-width: 600px){
      padding-top: 40px;
      padding-bottom: 40px;
    }
    a{
      width: 171px;
      height: 43px;
      background: #DC6B33;
      border-radius: 20px;
      font-family:$fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: -0.015em;
      color: #FFFFFF;

    }
  }

}
.setion_for_representation{
  padding-top: 80px;
  padding-bottom: 60px;
  @media screen and (max-width: 600px){
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .for_representation_title{
    font-family:$fontFamily;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #494949;

    @media screen and (max-width: 600px){
      font-family: $fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #494949;
      margin-bottom: 20px;
    }
  }
  .row_for_carousel{
    //padding: 0;
    padding-top: 40px;
    padding-bottom: 80px;
    @media screen and (max-width: 600px){
      // padding-left: 0;
      // padding-right: 0;
      padding: 0;
      padding-top: 0px;
      padding-bottom: 40px;
    }
    .slick-dots{
      margin-bottom: 0px !important;
    }
    .slick-prev{
      display: none !important;
    }
    .slick-next{
      display: none !important;
    }
    .slick-track{
      @media screen and (max-width: 600px){

      }
    }
    .img-card {
      border-radius: 4px;
      background: transparent;
      color: #666;
      display: flex !important;
      align-items: center;
      justify-content: center;
      height: 160px;
      overflow: hidden;
      transition: 0.3s;
      cursor: pointer;
    }
    .slick-dots{
      li{
        //position: absolute;
        bottom: -40px !important;
        @media screen and (max-width: 60px){
          bottom: 0px !important;
        }
        button{
          &::before{
            color: #0F62AC;
          }
        }
      }
    }
    .img-card:hover {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }

    .card-body {
      padding: 15px;
    }

    .card-title {
      font-size: 20px;
      font-weight: 600;
    }

    .card-text {
      margin-top: 10px;
    }

    /* card style end */


    /* carousel slider style start */

    .slick-slide > div {
      margin: 0 10px;
    }

    .slick-list {
      margin: 0 -10px;
    }

    .slick-slide *:focus{
      outline: none;
    }
  }

}
.slick-dots{
  position: absolute;
  //top: 0 !important;
  bottom: 0 !important;
}
.setion_for_news{
  padding-top: 40px;
  position: relative;
  padding-bottom: 80px;
  border-bottom:3px solid #F2F2F2;
  background: #F2F2F2;
  @media screen and (max-width: 600px){
    padding-top: 30px;
  }

  .for_news_title{
    font-family:$fontFamily;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.015em;
    color: #494949;
    text-align: center;
    padding-bottom: 60px;
    padding-top: 20px;
    @media screen and (max-width: 600px){
      font-family:$fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.015em;
      text-align: center;
      color: #494949;
    }
  }
  .row_for_news{
    .card-none{
      @media screen and (max-width: 600px){
        display: none;
      }
    }
    .hover-card{
      background: transparent !important;
      @media screen and (max-width: 600px){
        padding: 0;
      }
      .card {
        height: 200px;
        border-radius: 15px;
        padding: 1.5rem;
        background: none !important;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        transition: 0.4s ease-out;
        //box-shadow: -2px 4px 6px #DADADA;
        cursor:pointer;
        border: none;
      }
      .card:hover {
        transform: translateY(10px);
      }
      .card:hover:before {
        opacity: 1;
      }
      .card:hover .info {
        opacity: 1;
        transform: translateY(0px);

      }
      .card img{

      }
      .info{
        .title{
          font-family:$fontFamily;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 30px;
          letter-spacing: -0.015em;
          color: #fff;
        }
        a{
          color: #FFFFFF;
          text-decoration: none;
        }
      }
      .card:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        background: rgba(0, 0, 0, 0.6);
        z-index: 2;
        transition: 0.5s;
        opacity: 0;
      }
      .card img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 15px;
      }
      .card .info {
        position: relative;
        z-index: 3;
        color: white;
        opacity: 0;
        transform: translateY(30px);
        transition: 0.5s;
      }
      .card .info h1 {
        margin: 0px;
      }
      .card .info p {
        letter-spacing: 1px;
        font-size: 15px;
        margin-top: 8px;
      }
      .card .info button {
        padding: 0.6rem;
        outline: none;
        border: none;
        border-radius: 3px;
        background: white;
        color: black;
        font-weight: bold;
        cursor: pointer;
        transition: 0.4s ease;
      }
      .card .info button:hover {
        background: dodgerblue;
        color: white;
      }
    }

  }
  #link_for_see_more_small{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 171px;
    height: 43px;
    border: 1px solid #fff;
    box-sizing: border-box;
    border-radius: 20px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    //background: #0F62AC;
    border: 1px solid #0F62AC;
    color: #0F62AC;
    margin: auto;
    margin-top:82px;
    transition: all .3s linear;
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -ms-transition: all .3s linear;
    -o-transition: all .3s linear;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    &:hover{
      background: #73B3F2 !important;
      color: #FFFFFF !important;
    }
  }
  .flex_content{
    @media screen and (max-width: 576px){
      display: none;

    }
  }
}
.section_for_partners{
  padding-top: 40px;
  //padding-bottom: 40px;
  .partners_title{
    font-family: $fontFamily;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-align: center;
    color: #494949;
    padding: 20px 0;

  }
  .row_for_carousele{
    padding-bottom: 80px;
    @media screen and (max-width: 600px){
      padding: 0;
      padding-top: 0px;
      padding-bottom: 40px;
    }

    .slick-prev{
      display: none !important;
    }
    .slick-next{
      display: none !important;
    }
    .slick-track{
      @media screen and (max-width: 600px){

      }
    }
    .img-card {
      border-radius: 4px;
      background: transparent;
      color: #666;
      display: flex !important;
      align-items: center;
      justify-content: center;
      height: 200px;
      overflow: hidden;
      transition: 0.3s;
      img{
        max-width: 80%;
        max-height: 80%;
      }
      @media screen and (max-width: 600px){
        height: 140px;

      }

    }
    .slick-dots{
      li{
        bottom: -40px !important;
        @media screen and (max-width: 600px){
            bottom: -10px !important;
            top: 0;
        }
        button{
          &::before{
            color: #0F62AC;
          }
        }
      }
    }
    .img-card:hover {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }

    .card-body {
      padding: 15px;
    }

    .card-title {
      font-size: 20px;
      font-weight: 600;
    }

    .card-text {
      margin-top: 10px;
    }

    /* card style end */


    /* carousel slider style start */

    .slick-slide > div {
      margin: 0 10px;
    }

    .slick-list {
      margin: 0 -10px;
    }

    .slick-slide *:focus{
      outline: none;
    }
  }
}