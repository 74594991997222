@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.about_us_big_fluid {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}
.about_us_big_fluid .first_section_about_us {
  position: relative;
}
.about_us_big_fluid .first_section_about_us .first_section_left_box {
  padding: 30px 80px 30px 30px;
  box-shadow: 4px 5px 12px #DADADA;
  background: #F2F2F2;
}
@media screen and (max-width: 1200px) {
  .about_us_big_fluid .first_section_about_us .first_section_left_box {
    padding: 20px 20px 20px 20px;
  }
}
@media screen and (max-width: 992px) {
  .about_us_big_fluid .first_section_about_us .first_section_left_box {
    padding: 20px 20px 20px 20px;
    overflow-y: auto;
  }
}
@media screen and (max-width: 778px) {
  .about_us_big_fluid .first_section_about_us .first_section_left_box {
    padding: 30px 15px 0px 15px;
  }
}
@media screen and (max-width: 1200px) {
  .about_us_big_fluid .first_section_about_us .first_section_left_box .section_for_title_desc {
    height: 344px;
  }
}
@media screen and (max-width: 992px) {
  .about_us_big_fluid .first_section_about_us .first_section_left_box .section_for_title_desc {
    height: fit-content;
    height: 300px;
  }
}
.about_us_big_fluid .first_section_about_us .first_section_left_box .section_for_title_desc .first_big_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 992px) {
  .about_us_big_fluid .first_section_about_us .first_section_left_box .section_for_title_desc .first_big_title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #494949;
  }
}
.about_us_big_fluid .first_section_about_us .first_section_left_box .section_for_title_desc .second_big_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 992px) {
  .about_us_big_fluid .first_section_about_us .first_section_left_box .section_for_title_desc .second_big_title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #494949;
  }
}
.about_us_big_fluid .first_section_about_us .first_section_left_box .section_for_title_desc .first_desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.015em;
  color: #AAA9A9;
}
@media screen and (max-width: 992px) {
  .about_us_big_fluid .first_section_about_us .first_section_left_box .section_for_title_desc .first_desc {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #494949;
  }
}
.about_us_big_fluid .first_section_about_us .first_section_left_box .section_for_title_desc .second_desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #AAA9A9;
}
@media screen and (max-width: 992px) {
  .about_us_big_fluid .first_section_about_us .first_section_left_box .section_for_title_desc .second_desc {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #494949;
  }
}
.about_us_big_fluid .first_section_about_us .first_section_right_box {
  position: relative;
  z-index: 999999999999;
  padding-right: 0;
  padding-left: 30px;
}
@media screen and (max-width: 778px) {
  .about_us_big_fluid .first_section_about_us .first_section_right_box {
    padding-left: 0;
    margin-top: 30px;
  }
}
.about_us_big_fluid .first_section_about_us .first_section_right_box .first_section_right_box_img {
  padding-right: 0px;
  height: 100%;
}
@media screen and (max-width: 778px) {
  .about_us_big_fluid .first_section_about_us .first_section_right_box .first_section_right_box_img {
    padding-left: 0;
  }
}
.about_us_big_fluid .first_section_about_us .first_section_right_box .first_section_right_box_img img {
  height: 100%;
}

.second_section_about_us_fluid {
  position: relative;
  padding-top: 40px;
  padding-bottom: 60px;
}
.second_section_about_us_fluid .second_section_about_us {
  position: relative;
}
.second_section_about_us_fluid .second_section_about_us .mobile-size-none {
  display: block;
}
@media screen and (max-width: 768px) {
  .second_section_about_us_fluid .second_section_about_us .mobile-size-none {
    display: none;
  }
}
.second_section_about_us_fluid .second_section_about_us .mobile-size-block {
  display: none;
}
@media screen and (max-width: 768px) {
  .second_section_about_us_fluid .second_section_about_us .mobile-size-block {
    display: block;
    padding-right: 0;
  }
}
.second_section_about_us_fluid .second_section_about_us .left-content {
  position: relative;
  z-index: 999999999999;
}
.second_section_about_us_fluid .second_section_about_us .col-for-left-img {
  padding-left: 0;
}
.second_section_about_us_fluid .second_section_about_us .col-for-right-text {
  background: #F2F2F2;
  box-shadow: 4px 5px 12px #DADADA;
}
.second_section_about_us_fluid .second_section_about_us .col-for-right-text .second_section_for_title_desc {
  padding: 20px 60px 20px 40px;
}
@media screen and (max-width: 1200px) {
  .second_section_about_us_fluid .second_section_about_us .col-for-right-text .second_section_for_title_desc {
    overflow-y: scroll;
    height: 374px;
  }
}
@media screen and (max-width: 992px) {
  .second_section_about_us_fluid .second_section_about_us .col-for-right-text .second_section_for_title_desc {
    overflow-y: scroll;
    height: 277px;
  }
}
@media screen and (max-width: 778px) {
  .second_section_about_us_fluid .second_section_about_us .col-for-right-text .second_section_for_title_desc {
    padding: 20px 20px 20px 20px;
    height: 277px;
  }
}
.second_section_about_us_fluid .second_section_about_us .col-for-right-text .second_section_for_title_desc .first_big_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
  margin-top: 20px;
}
@media screen and (max-width: 992px) {
  .second_section_about_us_fluid .second_section_about_us .col-for-right-text .second_section_for_title_desc .first_big_title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #494949;
  }
}
.second_section_about_us_fluid .second_section_about_us .col-for-right-text .second_section_for_title_desc .second_big_title {
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 992px) {
  .second_section_about_us_fluid .second_section_about_us .col-for-right-text .second_section_for_title_desc .second_big_title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #494949;
  }
}
.second_section_about_us_fluid .second_section_about_us .col-for-right-text .second_section_for_title_desc .first_desc ul {
  padding: 0;
}
.second_section_about_us_fluid .second_section_about_us .col-for-right-text .second_section_for_title_desc .first_desc ul li {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #494949;
  margin-top: 10px;
}
@media screen and (max-width: 992px) {
  .second_section_about_us_fluid .second_section_about_us .col-for-right-text .second_section_for_title_desc .first_desc ul li {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #494949;
  }
}
.second_section_about_us_fluid .second_section_about_us .col-for-right-text .second_section_for_title_desc .second_desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #AAA9A9;
}
@media screen and (max-width: 992px) {
  .second_section_about_us_fluid .second_section_about_us .col-for-right-text .second_section_for_title_desc .second_desc {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #494949;
  }
}

.for_new_brands {
  margin-top: 0px;
  background: #00386A;
  padding-right: 0;
}
@media screen and (max-width: 576px) {
  .for_new_brands {
    background: #ffffff;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.for_new_brands .left_content {
  display: flex;
  align-items: center;
  padding: 40px 80px 40px 80px;
}
@media screen and (max-width: 992px) {
  .for_new_brands .left_content {
    padding: 40px 40px 40px 40px;
  }
}
@media screen and (max-width: 768px) {
  .for_new_brands .left_content {
    padding: 20px 10px;
  }
}
@media screen and (max-width: 578px) {
  .for_new_brands .left_content {
    padding: 20px 30px 20px 30px;
    background: #00386A;
  }
}
.for_new_brands .left_content #brand-link {
  background: #FFFFFF;
  border: 1px solid #DADADA;
  box-shadow: -2px 4px 4px #DADADA;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #00386A;
  padding: 10px 40px;
  position: relative;
  top: 30px !important;
}
.for_new_brands .left_content .section_for_title_desc .brand-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bolder;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
@media screen and (max-width: 768px) {
  .for_new_brands .left_content .section_for_title_desc .brand-title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
  }
}
.for_new_brands .left_content .section_for_title_desc .brand-desc-bold {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .for_new_brands .left_content .section_for_title_desc .brand-desc-bold {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
  }
}
.for_new_brands .left_content .section_for_title_desc .brand-desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .for_new_brands .left_content .section_for_title_desc .brand-desc {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    margin-top: 10px;
  }
}
.for_new_brands .right_content {
  padding-left: 0;
}
@media screen and (max-width: 576px) {
  .for_new_brands .right_content {
    padding-right: 0;
  }
}
.for_new_brands .right_content img {
  height: 100% !important;
}

.for_new_service {
  margin-top: 60px;
  margin-bottom: 100px;
  padding-left: 0;
  padding-right: 0;
  background: #0F62AC;
  box-shadow: 4px 5px 12px #DADADA;
}
@media screen and (max-width: 578px) {
  .for_new_service {
    background: #fff;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 30px;
    margin-bottom: 40px;
  }
}
.for_new_service .mobile-size-none {
  display: block;
}
@media screen and (max-width: 578px) {
  .for_new_service .mobile-size-none {
    display: none;
  }
}
.for_new_service .mobile-size-block {
  display: none;
}
@media screen and (max-width: 578px) {
  .for_new_service .mobile-size-block {
    display: block;
    padding-left: 0;
  }
}
.for_new_service .left_content {
  display: flex;
  align-items: center;
  padding: 40px 80px 40px 80px;
}
@media screen and (max-width: 992px) {
  .for_new_service .left_content {
    padding: 40px 40px 40px 40px;
  }
}
@media screen and (max-width: 768px) {
  .for_new_service .left_content {
    padding: 20px;
  }
}
@media screen and (max-width: 578px) {
  .for_new_service .left_content {
    padding: 20px 30px 20px 30px;
    background: #00386A;
  }
}
.for_new_service .left_content #brand-link {
  background: #FFFFFF;
  border: 1px solid #DADADA;
  box-shadow: -2px 4px 4px #DADADA;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #00386A;
  padding: 10px 40px;
  position: relative;
  top: 30px !important;
}
.for_new_service .left_content .section_for_title_desc .brand-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bolder;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
@media screen and (max-width: 768px) {
  .for_new_service .left_content .section_for_title_desc .brand-title {
    font-size: 16px;
    line-height: 20px;
  }
}
.for_new_service .left_content .section_for_title_desc .brand-desc-bold {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .for_new_service .left_content .section_for_title_desc .brand-desc-bold {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
  }
}
.for_new_service .left_content .section_for_title_desc .brand-desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
@media screen and (max-width: 768px) {
  .for_new_service .left_content .section_for_title_desc .brand-desc {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px;
  }
}
.for_new_service .right_content {
  padding-right: 0;
}
.for_new_service .right_content img {
  height: 100% !important;
}

.for_link_see {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .for_link_see {
    margin-top: 30px;
  }
}
.for_link_see a {
  background: #FFFFFF;
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 14.5px 40px 14.5px 40px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #0F62AC;
  transition: all 0.5s linear;
}
.for_link_see a:hover {
  background: #73B3F2 !important;
  color: #FFFFFF !important;
}
@media screen and (max-width: 768px) {
  .for_link_see a {
    padding: 6px 15px 6px 15px;
  }
}

.first-brand-fluid-section {
  background: #f1f1f1;
  padding-top: 40px;
  padding-bottom: 20px;
}
.first-brand-fluid-section .section_for_brands .brands_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bolder;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 600px) {
  .first-brand-fluid-section .section_for_brands .brands_title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #494949;
  }
}
.first-brand-fluid-section .section_for_brands .brands-desc {
  margin-top: 30px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 600px) {
  .first-brand-fluid-section .section_for_brands .brands-desc {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #494949;
  }
}

.section_for_br_tab {
  background: #F1F1F1;
  padding-bottom: 50px;
}
.section_for_br_tab .brands-tab-list {
  padding-top: 30px;
}
@media screen and (max-width: 600px) {
  .section_for_br_tab .brands-tab-list {
    display: none;
  }
}
.section_for_br_tab .brands-tab-list .react-tabs__tab-list {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 20px !important;
  background: transparent !important;
  border-bottom: none;
  padding: 0 !important;
}
@media screen and (max-width: 576px) {
  .section_for_br_tab .brands-tab-list .react-tabs__tab-list {
    justify-content: space-between;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab {
  background: #FFFFFF;
  box-shadow: -1px 4px 9px 1px #C4C4C4;
  border-radius: 10px;
  width: 230px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #494949;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 109px;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab {
    height: 80px;
  }
}
.section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab img {
  height: 80px;
}
@media screen and (max-width: 768px) {
  .section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab img {
    height: 66px;
  }
}
.section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab:nth-child(2) img {
  height: 74px;
}
@media screen and (max-width: 768px) {
  .section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab:nth-child(2) img {
    height: 60px;
  }
}
.section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab:nth-child(3) img {
  height: 74px;
}
@media screen and (max-width: 768px) {
  .section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab:nth-child(3) img {
    height: 60px;
  }
}
@media screen and (max-width: 992px) {
  .section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab {
    width: 190px !important;
  }
}
@media screen and (max-width: 768px) {
  .section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab {
    width: 160px !important;
  }
}
@media screen and (max-width: 600px) {
  .section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab {
    width: 160px !important;
    height: 69px;
    background: #FFFFFF;
    box-shadow: 3px 5px 17px #DADADA;
    border-radius: 10px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #494949;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab {
    background: #FFFFFF;
    box-shadow: 8px 16px 21px #DADADA;
    border-radius: 10px;
    width: 156px;
    height: 48.15px;
    margin-top: 23px;
    border: none;
  }
}
.section_for_br_tab .brands-tab-list .react-tabs__tab-list #react-tabs-1 {
  padding-bottom: 0px !important;
}
.section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab--selected {
  background: #F2F2F2;
  box-shadow: -1px 5px 9px 1px #C4C4C4;
  border-radius: 10px;
  padding-bottom: 0px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #FEFEFE;
}
.section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab--selected p {
  color: #FFFFFF;
}
@media screen and (max-width: 600px) {
  .section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab--selected {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
  }
}
@media screen and (max-width: 576px) {
  .section_for_br_tab .brands-tab-list .react-tabs__tab-list .react-tabs__tab--selected {
    background: #0F62AC;
    box-shadow: 8px 16px 21px #DADADA;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
  }
}
.section_for_br_tab .brands-tab-list-mobile {
  display: none;
}
@media screen and (max-width: 600px) {
  .section_for_br_tab .brands-tab-list-mobile {
    display: block;
    padding-top: 20px;
  }
}
.section_for_br_tab .brands-tab-list-mobile .react-tabs__tab-list {
  display: flex !important;
  justify-content: space-evenly;
  margin: 0;
  background: transparent !important;
  border-bottom: none;
  flex-direction: column;
  align-items: center !important;
  padding-left: 0;
}
.section_for_br_tab .brands-tab-list-mobile .react-tabs__tab-list .react-tabs__tab {
  cursor: pointer;
  background: #FFFFFF !important;
  box-shadow: 8px 16px 21px #DADADA;
  border-radius: 10px;
  width: 156px;
  height: 48.15px;
  margin: auto;
  margin-top: 23px !important;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 18px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
}
.section_for_br_tab .brands-tab-list-mobile .react-tabs__tab-list .react-tabs__tab:nth-child(1) {
  margin-top: 13px !important;
}
.section_for_br_tab .brands-tab-list-mobile .react-tabs__tab-list .react-tabs__tab:nth-child(3) {
  margin-bottom: 13px !important;
}
.section_for_br_tab .brands-tab-list-mobile .react-tabs__tab-list .react-tabs__tab--selected {
  background: #0F62AC !important;
  box-shadow: 8px 16px 21px #DADADA;
  border-radius: 10px;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-style: normal;
  line-height: 20px;
  font-size: 16px;
  align-items: center;
  display: flex;
  letter-spacing: -0.015em;
  text-align: center;
  color: #FFFFFF;
}

.for_icons {
  background: #0F62AC;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 30px;
}
@media screen and (max-width: 600px) {
  .for_icons {
    border-right: 10px solid #FFFFFF;
    border-left: 10px solid #FFFFFF;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 578px) {
  .for_icons {
    border-right: 10px solid #FFFFFF;
    border-left: 10px solid #FFFFFF;
    box-sizing: border-box;
  }
}
.for_icons .for_slider_icon .left-content {
  padding-left: 0;
}
.for_icons .for_slider_icon .left-content .left-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
@media screen and (max-width: 768px) {
  .for_icons .for_slider_icon .left-content .left-title {
    font-size: 16px;
    line-height: 20px;
  }
}
.for_icons .for_slider_icon .left-content .left-desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .for_icons .for_slider_icon .left-content .left-desc {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (max-width: 600px) {
  .for_icons .for_slider_icon .left-content .left-desc {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
}
.for_icons .for_slider_icon .flex-content-for-circle {
  display: flex;
  align-items: center;
  padding-left: 0;
}
@media screen and (max-width: 768px) {
  .for_icons .for_slider_icon .flex-content-for-circle {
    padding-left: 30px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 576px) {
  .for_icons .for_slider_icon .flex-content-for-circle {
    padding-left: 40px;
  }
}
@media screen and (max-width: 500px) {
  .for_icons .for_slider_icon .flex-content-for-circle {
    padding-left: 20px !important;
    padding-right: 50px !important;
  }
}
@media screen and (max-width: 440px) {
  .for_icons .for_slider_icon .flex-content-for-circle {
    padding-left: 30px !important;
    padding-right: 40px !important;
  }
}
.for_icons .for_slider_icon .flex-content-for-circle .slider_icon_circle_div {
  border: 1px solid #fff;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .for_icons .for_slider_icon .flex-content-for-circle .slider_icon_circle_div {
    margin-top: 10px;
  }
}
.for_icons .for_slider_icon .flex-content-for-circle .slider_icon_circle_div img {
  width: 34.59px;
  height: 29.53px;
}

.brand-tab-cont {
  padding-top: 40px;
  padding-bottom: 40px;
}
.brand-tab-cont .brand_slider_title_desc {
  padding: 0px 80px 0px 80px;
}
.brand-tab-cont .brand_slider_title_desc .brand_slider_title img {
  width: 163px;
  height: 109px;
}
.brand-tab-cont .brand_slider_title_desc .brand_slider_title .vita-logo {
  width: 170px;
  height: 74px;
}
@media screen and (max-width: 576px) {
  .brand-tab-cont .brand_slider_title_desc .brand_slider_title {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 992px) {
  .brand-tab-cont .brand_slider_title_desc {
    padding: 20px 20px 20px 20px;
    height: 351px;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 768px) {
  .brand-tab-cont .brand_slider_title_desc {
    padding: 20px 5px;
    height: 262.3px;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 576px) {
  .brand-tab-cont .brand_slider_title_desc {
    padding: 20px 80px;
    height: auto;
    overflow-y: visible;
  }
}
@media screen and (max-width: 500px) {
  .brand-tab-cont .brand_slider_title_desc {
    padding: 20px 40px;
  }
}
@media screen and (max-width: 375px) {
  .brand-tab-cont .brand_slider_title_desc {
    padding: 20px 20px;
  }
}
.brand-tab-cont .brand_slider_title_desc .brand_slider_desc_first {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #494949;
}
@media screen and (max-width: 992px) {
  .brand-tab-cont .brand_slider_title_desc .brand_slider_desc_first {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (max-width: 576px) {
  .brand-tab-cont .brand_slider_title_desc .brand_slider_desc_first {
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
  }
}
.brand-tab-cont .brand_slider_title_desc .brand_slider_desc_second {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #494949;
  text-align: justify;
  margin-top: 40px;
}
@media screen and (max-width: 992px) {
  .brand-tab-cont .brand_slider_title_desc .brand_slider_desc_second {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
}
.brand-tab-cont .content-for-img {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 576px) {
  .brand-tab-cont .content-for-img {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 576px) {
  .brand-tab-cont .content-for-img {
    padding: 0px 15px;
  }
}
@media screen and (max-width: 992px) {
  .brand-tab-cont .content-for-img img {
    height: auto;
  }
}

.first-section-for-serice {
  padding-top: 40px;
}
.first-section-for-serice .service-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #494949;
}
.first-section-for-serice .service-desc {
  padding: 0px 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #494949;
}

.second-section-for-serice {
  margin-top: 60px;
  background: #F1F1F1;
  box-shadow: 4px 5px 12px #DADADA;
}
@media screen and (max-width: 600px) {
  .second-section-for-serice {
    border-right: 15px solid #FFFFFF;
    border-left: 15px solid #FFFFFF;
    box-sizing: border-box;
  }
}
.second-section-for-serice .left-content-img {
  background: #fff;
  padding-right: 0;
  padding-left: 0;
}
.second-section-for-serice .right-content-text {
  padding-left: 50px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  height: 100%;
}
@media screen and (max-width: 992px) {
  .second-section-for-serice .right-content-text {
    height: 202px;
    overflow-y: scroll;
    padding-top: 140px;
  }
}
@media screen and (max-width: 768px) {
  .second-section-for-serice .right-content-text {
    height: 202px;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 10px;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 600px) {
  .second-section-for-serice .right-content-text {
    height: 202px !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 576px) {
  .second-section-for-serice .right-content-text {
    height: auto !important;
    overflow-y: visible;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.second-section-for-serice .right-content-text .product-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .second-section-for-serice .right-content-text .product-title {
    font-size: 16px;
    line-height: 20px;
  }
}
.second-section-for-serice .right-content-text .product-desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .second-section-for-serice .right-content-text .product-desc {
    font-size: 14px;
    line-height: 19px;
  }
}

.third-section-for-serice {
  box-shadow: 0px 6px -17px 4px #DADADA;
  background: transparent;
  margin-top: 40px;
}
.third-section-for-serice .Big_text_content {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 600px) {
  .third-section-for-serice .Big_text_content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.third-section-for-serice .mobile-block {
  display: none;
}
@media screen and (max-width: 576px) {
  .third-section-for-serice .mobile-block {
    display: block;
  }
  .third-section-for-serice .mobile-block img {
    width: 100%;
    height: 202px;
  }
}
.third-section-for-serice .mobile-none {
  display: block;
}
@media screen and (max-width: 576px) {
  .third-section-for-serice .mobile-none {
    display: none;
  }
}
.third-section-for-serice .right-content-text {
  background: #0F62AC;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 120px;
}
@media screen and (max-width: 992px) {
  .third-section-for-serice .right-content-text {
    height: 330px;
    overflow-y: scroll;
    justify-content: flex-start !important;
    flex-direction: column;
    padding-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .third-section-for-serice .right-content-text {
    height: 247px;
    overflow-y: scroll;
    justify-content: flex-start !important;
    flex-direction: column;
    padding-top: 30px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (max-width: 576px) {
  .third-section-for-serice .right-content-text {
    height: auto !important;
    overflow-y: visible;
    padding-right: 30px;
    padding-left: 30px;
  }
}
.third-section-for-serice .right-content-text .product-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
@media screen and (max-width: 767px) {
  .third-section-for-serice .right-content-text .product-title {
    font-size: 16px;
    line-height: 20px;
  }
}
.third-section-for-serice .right-content-text .product-desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  text-align: justify;
}
@media screen and (max-width: 767px) {
  .third-section-for-serice .right-content-text .product-desc {
    font-size: 14px;
    line-height: 19px;
  }
}
.third-section-for-serice .right-content-text ul {
  list-style: none;
  padding-left: 0;
}
.third-section-for-serice .right-content-text ul li {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  margin-top: 4px;
}
@media screen and (max-width: 767px) {
  .third-section-for-serice .right-content-text ul li {
    font-size: 14px;
    line-height: 17px;
  }
}
.third-section-for-serice .img-content {
  padding: 0;
}
@media screen and (max-width: 576px) {
  .third-section-for-serice .img-content {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.forth-section-for-serice {
  margin-top: 60px;
  background: #F1F1F1;
}
.forth-section-for-serice .left-content-img {
  background: #fff;
  padding-right: 0;
}
@media screen and (max-width: 576px) {
  .forth-section-for-serice .left-content-img {
    padding-right: 15px;
  }
}
@media screen and (max-width: 576px) {
  .forth-section-for-serice .left-content-img img {
    width: 100%;
    height: 299px;
  }
}
@media screen and (max-width: 576px) {
  .forth-section-for-serice .right-contect-fluid {
    border-left: 15px solid #FFFFFF;
    border-right: 15px solid #FFFFFF;
    box-sizing: border-box;
  }
}
.forth-section-for-serice .right-content-text {
  padding-left: 50px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  height: 100%;
}
@media screen and (max-width: 992px) {
  .forth-section-for-serice .right-content-text {
    padding: 20px;
    height: 269px;
    overflow-y: scroll;
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 768px) {
  .forth-section-for-serice .right-content-text {
    padding: 20px;
    height: 199px;
    overflow-y: scroll;
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 576px) {
  .forth-section-for-serice .right-content-text {
    padding: 20px;
    height: auto;
    overflow-y: visible;
    justify-content: flex-start !important;
    box-sizing: border-box;
  }
}
.forth-section-for-serice .right-content-text .product-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 768px) {
  .forth-section-for-serice .right-content-text .product-title {
    font-size: 16px;
    line-height: 20px;
  }
}
.forth-section-for-serice .right-content-text .product-desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .forth-section-for-serice .right-content-text .product-desc {
    font-size: 14px;
    line-height: 19px;
  }
}

.end-prew {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
}
.end-prew a {
  width: 171px;
  height: 43px;
  background: #FFFFFF;
  border: 1px solid #DADADA;
  box-sizing: border-box;
  box-shadow: -2px 4px 4px #DADADA;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #494949;
  transition: all 0.5s linear;
}
.end-prew a:hover {
  background: #73B3F2 !important;
  color: #FFFFFF !important;
}

