#loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(134, 130, 136, 0.3);
    border-radius: 50%;
    border-top-color: #6F64F8;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}