@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$fontFamily:'Montserrat' , sans-serif;

.representation-header-content{
  background: #00386A;
  box-shadow: 0px 0px 12px #F2F2F2;
  margin-top: 40px;
  padding-right: 0;
  @media screen and (max-width:768px){
    background: transparent;
    box-shadow: none;
    padding-left: 0;
  }
  @media screen and (max-width:578px){
    background: transparent;
    box-shadow: none;
    padding-left: 15px;
    padding-right: 15px;
  }
  .right-content-img{
    img{
      @media screen and (max-width:992px){
        height: 100%;
      }
      //@media screen and (max-width:778px){
      //  height: 200px;
      //}
    }
  }
  .representation-text{
    padding: 0px 20px;
    margin-top: 66px;
    @media screen and (max-width:1200px){
      margin-top: 20px;
    }
    @media screen and (max-width:992px){
      height: 247px;
      overflow-y: scroll;
      padding-left: 10px;
    }
    @media screen and (max-width:992px){
      height: auto;
      overflow-y: visible;
      padding-left: 10px;
    }
    @media screen and (max-width:768px){
      background: #00386A;
      box-shadow: 0px 0px 12px #F2F2F2;
      padding: 20px 15px;
    }
    .representation-title{
      font-family:$fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #FFFFFF;
      @media screen and (max-width:992px){
        font-size: 16px;
        line-height: 20px;
      }

    }
    .representation-title-second{
      font-family:$fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.015em;
      color: #FFFFFF;
      @media screen and (max-width:992px){
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        padding: 0;
        text-align: justify;

      }
    }
    .representation-desc{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.015em;
      color: #FFFFFF;
      padding-right: 20px;
      text-align: justify;
      @media screen and (max-width:992px){
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        //text-align: justify;
        padding-right: 0;
      }
    }

  }
}
.for_computer_size{
  @media screen and (max-width: 768px){
    display: none;
  }
  .scrl-content{
    position: relative;
    z-index: 999999999;

  }
  .left-button{
    position: absolute;
    top: 60px;
    left: 0px;
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    border-radius: 50%;
    z-index: 99999999999999999999999999;
    background: transparent;
    img{
      width: 30px;
      height: 30px;
      animation-name: imgSize1;
      animation-duration: 3s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      @keyframes imgSize1 {
        from{
          transform: scale(1);
          opacity: 0.6;
        }
        to{
          transform: scale(1.2);
          opacity: 1;
        }
      }
    }
  }
  .right-button{
    position: absolute;
    top: 60px;
    right: 0px;
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    border-radius: 50%;
    z-index: 999999999999999999999;
    background: transparent;
    img{
      width: 30px;
      height: 30px;
      animation-name: imgSize;
      animation-duration: 3s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      @keyframes imgSize {
        from{
          transform: scale(1);
          opacity: 0.6;
        }
        to{
          transform: scale(1.2);
          opacity: 1;
        }
      }
    }

  }

  .full-width-desc{
    background: #F1F1F1;
    padding-top: 60px;
    padding-bottom: 60px;
    .title-description{
      padding-left: 20px;
      padding-right: 20px;
      .title{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #494949;
      }
      .description{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #494949;
        margin-bottom: 0;
        text-align: justify;
      }
      .description-second{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        margin: 0;
        //line-height: 20px;
        letter-spacing: -0.015em;
        color: #494949;
      }
    }
  }
  .for_tab_first{
    margin-top: 60px;
    padding-top: 70px;
    position: relative;

    .for_tab_first_title_dsc{
      padding-left: 20px;
      padding-right: 20px;
      .tab_ul_desc{
        padding-left: 28px;
        margin-top: 20px !important;
        li{
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.015em;
        }
      }
      .for_tab_desc_first{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #494949;
        margin-top: 40px;
        //text-align: justify;
      }
      .for_tab_desc_second{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #494949;
        margin-top: 20px;

      }
    }
    .for_tab1_image{
      position: relative;
      z-index:999999999999;
      display: flex;
      justify-content: center;
      height: 100%;
      @media screen and (max-width: 768px) {
        margin-top:40px;
      }
    }
  }
  .for_tab_first_fluid{
    margin-top: 60px;
    padding-top: 70px;
    padding-bottom:70px;
    position: relative;
    background:#F7F7F7 ;
    .for_tab_first_title_dsc{
      padding-left: 86px;
      padding-right: 20px;
      .title_icon{
        @media screen and (max-width:600px) {
        }
      }
      .tab_ul_desc{
        padding-left: 28px;
        margin-top: 20px !important;
        li{
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.015em;
        }
      }
      .for_tab_desc_first{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #494949;
        margin-top: 40px;
        //text-align: justify;
      }
      .for_tab_desc_second{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #494949;
        margin-top: 20px;

      }
    }
    .for_tab1_image{
      position: relative;
      z-index:999999999999;
      display: flex;
      justify-content: center;
      height: 100%;
      @media screen and (max-width: 768px) {
        margin-top:40px;
      }
    }
  }
}
#for-select-selected{
  position: relative;
  .react-tabs__tab-list{
    background: transparent !important;
    //display:flex;
    //justify-content: space-evenly;
    border: none;
    margin-bottom: 0;
    padding-left: 0;
    .react-tabs__tab{
      width: 25% !important;
      display: flex;
      //background: #F1F1F1;
      justify-content: center !important;
      align-items: center !important;
      transition: all .3s linear;
    }
    li{
      display: inline-block !important;
      height: 149px;
      padding: 0;
      .flex_center_align{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          max-width: 80%;
          max-height: 60px;
        }
      }

    }
    .react-tabs__tab--selected{
      position: relative;
      background: #FFFFFF;
      box-shadow: 0px 0px 13px 1px #DADADA;
      border: none !important;
      outline: none !important;
    }
  }

}

#for-mobile-click{
  @media screen and (max-width: 778px){
    display: block;
  }
  @media screen and (min-width: 778px){
    display: none;
  }

  .row_for_brands_modal{
    padding-bottom: 60px;
    padding-left: 34px;
    padding-right: 34px;
    @media screen and (max-width:578px){
      padding-left: 0;
      padding-right: 0;
    }
    .mobile_brand_logo{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border: 1px solid rgba(218, 218, 218, 1);
      padding-top: 20px;
      padding:20px 5px;
    }

  }

}
.modal-body{
  padding-top: 0;

  .for_tab_first_title_dsc{
    margin-top: 20px;
  }
}

#mobile-title-brand{
  background: #F1F1F1;
  padding-top: 60px;
  padding-bottom: 30px;

  .mobile-title{
    font-family: $fontFamily;
    font-style: normal;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #494949;
  }

  .mobile-desc{
    font-family:$fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #494949;
    text-align: justify;
    margin-top: 20px;
  }

}
.base_section_for_brand_mobile_size{
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid#C4C4C4;
  .tab_ul_desc{
    padding-left: 14px;
    margin-top: 20px !important;
    li{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: -0.015em;
    }
  }
  .second_base_row{
    padding-bottom: 30px;
    .base-title{
      margin-top: 20px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.015em;
      color: #494949;
    }
    .base-desc{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.015em;
      color: #494949;
    }
  }
}
#small_icon_for_modal{
  transform: scale(0.8);
  margin-top: 20px;
}
