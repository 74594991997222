@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap");
@media screen and (max-width: 1200px) {
  #section_for_carousel {
    margin-top: 40px;
  }
}
@media screen and (max-width: 576px) {
  #section_for_carousel {
    margin-top: 0;
  }
}
#section_for_carousel #padding_none {
  padding: 0;
}
@media screen and (max-width: 576px) {
  #section_for_carousel #padding_none {
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (max-width: 576px) {
  #section_for_carousel img {
    height: 175px;
  }
}
#section_for_carousel .carousel-control-prev {
  display: none;
}
#section_for_carousel .carousel-control-next {
  display: none;
}

.footer {
  background: #00386a;
  padding-top: 60px;
  padding-bottom: 40px;
}
@media screen and (max-width: 600px) {
  .footer {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
.footer .first_row .first_row_first_col div {
  padding: 0;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_first_col div img {
    width: 154px;
    height: 46px;
  }
}
.footer .first_row .first_row_first_col .for_see_more_Link {
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
}
.footer .first_row .first_row_first_col .for_see_more_Link .for_see_more_link {
  width: 171px;
  height: 43px;
  background: #DC6B33;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_first_col .for_see_more_Link .for_see_more_link {
    width: 156.52px;
    height: 43.04px;
    border-radius: 50px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
  }
}
.footer .first_row .first_row_second_col {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_second_col {
    display: block;
    text-align: center;
  }
}
.footer .first_row .first_row_second_col span {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_second_col span {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
  }
}
.footer .first_row .first_row_second_col ul {
  list-style: none;
  padding: 0;
  padding-left: 10px;
  margin-top: 10px;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_second_col ul {
    padding-left: 0;
  }
}
.footer .first_row .first_row_second_col ul li a {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #FFFFFF !important;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_second_col ul li a {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
  }
}
.footer .first_row .first_row_third_col {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_third_col {
    display: block;
    text-align: center;
  }
}
.footer .first_row .first_row_third_col span {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  margin-left: -34px;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_third_col span {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    margin-left: 0px;
  }
}
.footer .first_row .first_row_third_col ul {
  list-style: none;
  padding: 0;
  padding-left: 0;
  margin-top: 10px;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_third_col ul {
    padding-left: 0;
  }
}
.footer .first_row .first_row_third_col ul li a {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF !important;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_third_col ul li a {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
  }
}
.footer .first_row .first_row_forth_col {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_forth_col {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.footer .first_row .first_row_forth_col div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_forth_col div {
    align-items: center;
  }
}
.footer .first_row .first_row_forth_col div p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  margin-bottom: 8px;
  text-align: left;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_forth_col div p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
  }
}
.footer .first_row .first_row_forth_col div p a {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF !important;
}
@media screen and (max-width: 600px) {
  .footer .first_row .first_row_forth_col div p a {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
  }
}
.footer .first_row .first_row_forth_col div .margin-none {
  margin: 0 !important;
}
.footer .second_row {
  margin-top: 80px;
}
@media screen and (max-width: 600px) {
  .footer .second_row .map_computer_size {
    display: none;
  }
}
.footer .second_row .map_for_mobile {
  padding: 0;
  display: none;
}
@media screen and (max-width: 600px) {
  .footer .second_row .map_for_mobile {
    display: block;
  }
}
.footer .second_row .right_for_us {
  padding-left: 54px;
}
@media screen and (max-width: 600px) {
  .footer .second_row .right_for_us {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 25vw;
  }
}
@media screen and (max-width: 600px) {
  .footer .second_row .right_for_us {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 18vw;
  }
}
@media screen and (max-width: 500px) {
  .footer .second_row .right_for_us {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 27vw;
  }
}
.footer .second_row .right_for_us .call_us {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
@media screen and (max-width: 600px) {
  .footer .second_row .right_for_us .call_us {
    text-align: center;
    margin-left: 40px;
  }
}
@media screen and (max-width: 600px) {
  .footer .second_row .right_for_us .call_us {
    text-align: center;
    margin-left: 20px;
  }
}
.footer .second_row .right_for_us .number_icon {
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 600px) {
  .footer .second_row .right_for_us .number_icon {
    padding-left: 0px;
    justify-content: center;
  }
}
.footer .second_row .right_for_us .number_icon .phone_icon {
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  width: 39.63px;
  height: 39.63px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .second_row .right_for_us .number_icon .phone_numbers {
  display: flex;
  align-items: flex-end;
}
.footer .second_row .right_for_us .number_icon .phone_numbers ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}
.footer .second_row .right_for_us .number_icon .phone_numbers ul li {
  padding: 0;
}
.footer .second_row .right_for_us .number_icon .phone_numbers ul li a {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #FFFFFF;
}
.footer .second_row .right_for_us .email {
  display: flex;
  margin-top: 20px;
}
.footer .second_row .right_for_us .email .email_icon {
  width: 39.63px;
  height: 39.63px;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .second_row .right_for_us .email .email_icon img {
  width: 22.89px;
  height: 17.25px;
}
.footer .second_row .right_for_us .email .email_inbox {
  display: flex;
  align-items: center;
  margin-left: 13px;
  width: 39.63px;
  height: 39.63px;
}
.footer .second_row .right_for_us .email .email_inbox a {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
.footer .second_row .right_for_us .adress {
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .footer .second_row .right_for_us .adress {
    justify-content: center;
  }
}
.footer .second_row .right_for_us .adress .address_icon {
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  width: 49.63px;
  height: 39.63px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .second_row .right_for_us .adress .address_soft {
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.footer .second_row .right_for_us .adress .address_soft ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}
.footer .second_row .right_for_us .adress .address_soft ul li {
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #FFFFFF;
}
.footer .second_row .right_for_us .social_icons {
  display: flex;
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .footer .second_row .right_for_us .social_icons {
    justify-content: center;
  }
}
.footer .second_row .right_for_us .social_icons .facebook_link {
  width: 42px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #DC6B33;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.footer .second_row .right_for_us .social_icons .facebook_link img {
  width: 19px;
  height: 32px;
}
.footer .second_row .right_for_us .social_icons .instagram_link {
  margin-left: 30px;
  width: 42.06px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #DC6B33;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.footer .second_row .right_for_us .social_icons .instagram_link img {
  width: 25.47px;
  height: 26.04px;
}

.section_for_brands_slider {
  background: #fff;
  position: relative;
  z-index: 9999999999;
}
.section_for_brands_slider .brand_slider_title_desc {
  padding-right: 120px;
}
.section_for_brands_slider .brand_slider_title_desc .brand_slider_title {
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.015em;
  color: #494949;
}
.section_for_brands_slider .brand_slider_title_desc .brand_slider_desc_first {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
}
.section_for_brands_slider .brand_slider_title_desc .brand_slider_desc_forth {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
}
.section_for_brands_slider .brand_slider_title_desc .brand_slider_desc_second {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
}
.section_for_brands_slider .brand_slider_title_desc .brand_slider_desc_third {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
}
.section_for_brands_slider .for_slider_icon {
  margin-top: 40px;
}
.section_for_brands_slider .for_slider_icon .slider_icon_circle_div {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c4c4c4;
  border-radius: 50%;
  width: 106.27px;
  height: 106.68px;
}
.section_for_brands_slider .for_slider_icon .slick-prev {
  background: red;
  display: block !important;
}
.section_for_brands_slider .for_slider_icon .slick-next {
  display: block !important;
}
.section_for_brands_slider .slick-dots li {
  bottom: -40px !important;
}
.section_for_brands_slider .slick-dots li button::before {
  color: #0F62AC;
}

.for_copyright {
  background: #F2F2F2;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: #494949;
  padding-top: 10px;
  padding-bottom: 10px;
}
.for_copyright ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
.for_copyright ul li {
  margin-left: 10px;
}

