@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
select {
  border: none !important;
  outline: none !important;
}
@media screen and (max-width: 1200px) {
  #section_for_carousel {
    margin-top: 40px;
  }
}
@media screen and (max-width: 576px) {
  #section_for_carousel {
    margin-top: 0;
  }
}
#section_for_carousel #padding_none {
  padding: 0;
}
@media screen and (max-width: 576px) {
  #section_for_carousel #padding_none {
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (max-width: 500px) {
  #section_for_carousel img {
    height: 146px !important;
  }
}
@media screen and (max-width: 414px) {
  #section_for_carousel img {
    height: 121px !important;
  }
}
@media screen and (max-width: 375px) {
  #section_for_carousel img {
    height: 110px !important;
  }
}
@media screen and (max-width: 320px) {
  #section_for_carousel img {
    height: 93px !important;
  }
}
#section_for_carousel .carousel-control-prev {
  display: none;
}
#section_for_carousel .carousel-control-next {
  display: none;
}
#section_for_carousel .carousel-indicators {
  bottom: -50px !important;
}
#section_for_carousel .carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  background: #C4C4C4;
  border: none;
}

.MuiSelect-root img, .MuiSelect-select img, .MuiSelect-selectMenu img, .MuiInputBase-input img, .MuiInput-input img {
  position: relative !important;
  z-index: 0 !important;
}

.MuiList-root li, .MuiMenu-list li, .MuiList-padding li {
  position: relative;
  z-index: 99999999999999 !important;
}

