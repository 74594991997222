@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
$fontFamily:'Montserrat' , sans-serif;
#section_for_carousel{
  @media screen and (max-width:1200px){
    margin-top: 40px;
  }   @media screen and (max-width:576px){
  margin-top: 0;
}
  #padding_none{
    padding: 0;
    @media screen and (max-width:576px){
      padding-right: 0;
      padding-left: 0;
    }
  }

  img{
    @media screen and (max-width: 576px){
      height: 175px;
    }
  }

  .carousel-control-prev{
    display: none;
  }
  .carousel-control-next{
    display: none;
  }
}
.footer{
  background: rgba(0, 56, 106, 1);
  padding-top:60px;
  padding-bottom:40px;
  @media screen and (max-width:600px){
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .first_row{
    .first_row_first_col{
      div{
        padding:0;
        display:flex;
        justify-content: center;
        img{
          @media screen and (max-width:600px){
            width: 154px;
            height:46px;
          }
        }
      }
      .for_see_more_Link{
        display: flex;
        justify-content: center;
        margin-top: 40px !important;
        .for_see_more_link {
          width: 171px;
          height: 43px;
          background: #DC6B33;
          border-radius: 20px;
          font-family:$fontFamily;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          letter-spacing: -0.015em;
          color: #FFFFFF;
          @media screen and (max-width:600px){
            width: 156.52px;
            height: 43.04px;
            border-radius: 50px;
            -moz-border-radius: 50px;
            -webkit-border-radius: 50px;
          }
        }
      }
    }
    .first_row_second_col{
      padding: 0;
      margin: 0;
      display:flex;
      align-items: center;
      flex-direction: column;
      @media screen and (max-width:600px){
        display:block;
        text-align:center;
      }
      span{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        @media screen and (max-width:600px){
          font-family: $fontFamily;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
        }
      }
      ul{
        list-style: none;
        padding:0;
        padding-left: 10px;
        margin-top:10px;
        @media screen and (max-width:600px){
          padding-left: 0;
        }
        li{

          a{
            text-decoration: none;
            font-family:$fontFamily;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.015em;
            color: #FFFFFF !important;
            text-align: center;
            @media screen and (max-width:600px){
              font-family: $fontFamily;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: -0.015em;
              color: #FFFFFF;
            }
          }
        }
      }
    }
    .first_row_third_col{
      padding: 0;
      margin: 0;
      display:flex;
      align-items: center;
      flex-direction: column;
      @media screen and (max-width:600px){
        display:block;
        text-align:center;
      }
      span{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        margin-left: -34px;
        @media screen and (max-width:600px){
          font-family:$fontFamily;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
          margin-left: 0px;

        }
      }
      ul{
        list-style: none;
        padding:0;
        padding-left: 0;
        margin-top:10px;
        @media screen and (max-width:600px){
          padding-left: 0;

        }
        li{
          a{
            text-decoration: none;
            font-family:$fontFamily;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.015em;
            color: #FFFFFF !important;
            @media screen and (max-width:600px){
              font-family: $fontFamily;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: -0.015em;
              color: #FFFFFF;
            }
          }
        }
      }
    }
    .first_row_forth_col{
      padding: 0;
      margin: 0;
      display:flex;
      justify-content:space-between;
      align-items: center;
      flex-direction: column;
      width:100%;
      @media screen and (max-width:600px){
        display:flex;
        justify-content:center;
        align-items: center;
        text-align: center;
      }
      div{
        display: flex;
        flex-direction: column;
        justify-content: flex-start !important;
        @media screen and (max-width:600px){
          align-items: center;
        }
        p{
          font-family:$fontFamily;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
          margin-bottom: 8px;
          text-align: left;
          @media screen and (max-width:600px){

            font-family:$fontFamily;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: -0.015em;
            color: #FFFFFF;
          }
          a{
            text-decoration: none;
            font-family:$fontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.015em;
            color: #FFFFFF !important;
            //text-align: left;
            @media screen and (max-width:600px){
              font-family:$fontFamily;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              letter-spacing: -0.015em;
              color: #FFFFFF;
            }
          }
        }
        .margin-none{
          margin: 0 !important;
        }
      }

    }
  }
  .second_row{
    margin-top:80px;
    .map_computer_size{
      @media screen and (max-width:600px){
        display:none;
      }
    }
    .map_for_mobile{
      padding:0;
      display:none;
      @media screen and (max-width:600px){
        display:block;
      }
    }
    .right_for_us{
      padding-left: 54px;
      @media screen and (max-width: 600px) {
        //padding-left:0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 25vw;
      }
      @media screen and (max-width: 600px) {
        //padding-left:0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 18vw;
      }
      @media screen and (max-width: 500px) {
        //padding-left:0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 27vw;
      }

      .call_us{
        font-family:$fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        @media screen and (max-width: 600px) {
          text-align:center;
          margin-left: 40px;
        }
        @media screen and (max-width: 600px) {
          text-align:center;
          margin-left: 20px;
        }
      }
      .number_icon{
        margin-top:30px;
        display: flex;
        align-items: flex-end;
        @media screen and (max-width:600px){
          padding-left:0px;
          justify-content: center;
        }
        .phone_icon{
          border: 1px solid #fff;
          box-sizing: border-box;
          border-radius: 50%;
          -webkit-border-radius:50%;
          -moz-border-radius:50%;
          -ms-border-radius:50%;
          -o-border-radius:50%;
          width: 39.63px;
          height: 39.63px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .phone_numbers{
          display: flex;
          align-items: flex-end;
          ul{
            list-style: none;
            padding: 0;
            margin: 0;
            margin-left: 10px;
            li{
              padding: 0;
              a{
                //font-family: $fontFamily;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.015em;
                color: #FFFFFF;
              }
            }
          }
        }
      }
      .email{
        display:flex;

        margin-top:20px;
        @media screen and (max-width:600px){
          //padding-left:132px;
          // justify-content: center;
        }
        .email_icon{
          width: 39.63px;
          height:39.63px;
          border: 1px solid #fff;
          border-radius: 50%;
          -webkit-border-radius:50%;
          -moz-border-radius:50%;
          -ms-border-radius:50%;
          -o-border-radius:50%;
          display: flex;
          justify-content:center;
          align-items: center;
          img{
            width: 22.89px;
            height: 17.25px;
          }
        }
        .email_inbox{
          display: flex;
          align-items:center;
          margin-left: 13px;
          width: 39.63px;
          height: 39.63px;
          a{
            font-family:$fontFamily;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.015em;
            color: #FFFFFF;
          }
        }

      }
      .adress{
        display: flex;
        align-items: flex-end;
        margin-top:20px;

        @media screen and (max-width:600px){
          // margin-left:-21px;
          justify-content: center;
          //padding-left: 56px;
        }
        .address_icon{
          border: 1px solid #fff;
          box-sizing: border-box;
          border-radius: 50%;
          -webkit-border-radius:50%;
          -moz-border-radius:50%;
          -ms-border-radius:50%;
          -o-border-radius:50%;
          width: 49.63px;
          height: 39.63px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .address_soft{
          width: 100%;
          display: flex;
          align-items: flex-end;
          ul{
            list-style: none;
            padding: 0;
            margin: 0;
            margin-left: 10px;
            li{
              padding: 0;
              font-family:$fontFamily;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 22px;
              letter-spacing: 0.015em;
              color: #FFFFFF;
            }
          }
        }
      }
      .social_icons{
        display: flex;
        margin-top:20px;
        @media screen and (max-width:600px){
          justify-content:center;
        }
        .facebook_link{
          width: 42px;
          height: 43px;
          display: flex;
          justify-content:center;
          align-items: center;
          border: 1px solid #DC6B33;
          border-radius: 50%;
          -webkit-border-radius:50%;
          -moz-border-radius:50%;
          -ms-border-radius:50%;
          -o-border-radius:50%;
          img{
            width:19px;
            height: 32px;
          }
        }
        .instagram_link{
          margin-left: 30px;
          width: 42.06px;
          height: 43px;
          display: flex;
          justify-content:center;
          align-items: center;
          border: 1px solid #DC6B33;
          border-radius: 50%;
          -webkit-border-radius:50%;
          -moz-border-radius:50%;
          -ms-border-radius:50%;
          -o-border-radius:50%;
          img{
            width:25.47px;
            height:26.04px;
          }
        }
      }
    }
  }

}
.section_for_brands_slider{
  background:#fff;
  position:relative;
  z-index:9999999999;

  .brand_slider_title_desc{
    padding-right: 120px;
    .brand_slider_title{
      margin-top: 20px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.015em;
      color: #494949;
    }
    .brand_slider_desc_first{
      font-family:$fontFamily;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #494949;
    }
    .brand_slider_desc_forth{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #494949;
    }
    .brand_slider_desc_second{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #494949;
    }
    .brand_slider_desc_third{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #494949;
    }
  }
  .for_slider_icon{
    margin-top: 40px;
    .slider_icon_circle_div{
      display:flex;
      justify-content: center;
      align-items: center;
      background: rgba(196, 196, 196, 1);
      border-radius:50%;
      width: 106.27px;
      height:106.68px;
    }
    .slick-prev{
      background:red;
      display: block !important;
    }
    .slick-next{
      display: block !important;
    }

  }
  .slick-dots{
    li{
      //position: absolute;
      bottom: -40px !important;
      button{
        &::before{
          color: #0F62AC;
        }
      }
    }
  }
}
.for_copyright{
  background: #F2F2F2;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: #494949;
  padding-top: 10px;
  padding-bottom: 10px;
  ul{
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      margin-left: 10px;
    }
  }
}



