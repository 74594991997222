$fontFamily:'Montserrat' , sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#product-first-section{
  background: #fff;
  padding-top: 30px;
  padding-bottom: 60px;
  @media screen and (max-width: 600px) {
    background:#fff !important;
  }
  .left_content_for_height_bg{
    background: rgba(255, 255, 255, 1);
    padding: 0;
    box-shadow: 2px 2px 12px #C4C4C4;
    height:fit-content;
    @media screen and (max-width: 600px) {
      background: none;
      padding: 0 5px;
      box-shadow: none;
      
    }

    // #collasible-nav-dropdown-other{
    //   padding: 25px 20px !important;
    //   margin: 0;
    //   width: 100%;
    //   height: 100%;
    //   transition: all .5s linear;
    //   font-family: $fontFamily;
    //   font-style: normal;
    //   font-weight: normal;
    //   font-size: 16px;
    //   line-height: 20px;
    //   letter-spacing: -0.015em;
    //   border-bottom: 1px solid #E5E5E5;
    //   @media screen and (max-width: 768px){
    //     padding: 15px 20px !important;
    //     font-size: 13px;
    //   }
    //   &:hover a{
    //     color: #fff;
    //   }
    //   a{
    //     transition: all .5s linear;
    //     padding: 0;
    //     color: #494949;
    //     &:hover{
    //       background: transparent;
    //     }
    //   }
    // }
    // #tab-computer-size{
    //   .dropdaowns-menu{
    //     width: 100%;
    //     padding: 0;
    //     margin: 0;
    //     .dropdown{
    //       padding: 0;
    //       margin: 0;
    //       .dropdown-toggle{
    //         padding: 25px 20px;
    //         margin: 0;
    //         width: 100%;
    //         height: 100%;
    //         transition: all .5s linear;
    //         font-family: $fontFamily;
    //         font-style: normal;
    //         font-weight: normal;
    //         font-size: 16px;
    //         line-height: 20px;
    //         letter-spacing: -0.015em;
    //         color: #494949;
    //         border-bottom: 1px solid #E5E5E5;
    //         &::after{
    //           position: absolute;
    //           right: 20px !important;
    //           top: 34px !important;
    //         }
    //         &:hover{
    //           background: #73B3F2;
    //           color:#fff;
    //         }
    //       }
    //       .active{
    //         background: #73B3F2;
    //         color:#fff;
    //       }
    //       .show{
    //         margin-top: -4px;
    //         padding: 0;
    //         border: none;
    //         width: 100%;
    //         background: #73B3F2;
    //         &:hover{
    //           background:#F1F1F1;
    //         }
    //       }
    //       .active{
    //         background:#00386A;
    //       }
    //     }
    //   }
    //   @media screen and (max-width: 778px) {
    //     display: none;
    //   }
    // }

    #tab-mobile-size{
      display: none;
      // @media screen and (max-width: 778px) {
      //   display: block;
      //   background: #FFFFFF;
      //   box-shadow: 3px 5px 17px #DADADA;
      //   border-top-left-radius: 10px;
      //   border-top-right-radius: 10px;
      //   width: 100%;

      //   .dropdaowns-menu{

      //     width: 100%;
      //     padding: 0;
      //     margin: 0;
      //     #drop-menu{
      //       padding-top: 10px;
      //       padding-bottom:10px;
      //       font-family: $fontFamily;
      //       font-style: normal;
      //       font-weight: normal;
      //       font-size: 13px;
      //       line-height: 16px;
      //       letter-spacing: -0.015em;
      //     }
      //     .dropdown{
      //       padding: 0;
      //       margin: 0;
      //       .dropdown-toggle{
      //         padding: 15px 20px;
      //         margin: 0;
      //         transition: all .5s linear;
      //         font-family: $fontFamily;
      //         font-style: normal;
      //         font-weight: normal;
      //         font-size: 13px;
      //         line-height: 16px;
      //         letter-spacing: -0.015em;
      //         color: #494949;
      //         border-bottom: 1px solid #E5E5E5;
      //         &::after{
      //           position: absolute;
      //           right: 20px !important;
      //           top: 22px !important;
      //         }
      //         &:hover{
      //           background: #73B3F2;
      //           color:#fff;
      //         }
      //         .dropdown-item{
      //           background: #F1F1F1 !important;
      //         }
      //         .dropdown-item:hover{
      //           background: #f1f1f1 !important;
      //         }
      //       }

      //       .active{
      //         background: #73B3F2;
      //         color:#fff;
      //       }
      //       .show{
      //         margin-top: -4px;
      //         padding: 0;
      //         border: none;
      //         width: 100%;
      //         background: #73B3F2;
      //         &:hover{
      //           background:#73B3F2;
      //         }
      //       }
      //       .active{
      //         background: transparent;
      //       }
      //     }
      //   }
      //   .nav-link{
      //     font-family: $fontFamily;
      //     font-style: normal;
      //     font-weight: 500;
      //     font-size: 13px;
      //     line-height: 16px;
      //     letter-spacing: -0.015em;
      //     color: #494949;
      //     &::after{
      //       position: absolute;
      //       right: 20px !important;
      //       top: 14px !important;
      //     }
      //   }
      // }
    }
  }


  .react-tabs__tab-panel{
    padding-bottom: 0;
  }
  .section_for_wet_napkins{
    background: #E0A818;
    height:700px;
    .wet_napkins_text{
      padding: 40px 20px;
      .big_title{
        font-family: Montserrat arm;
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        padding: 0;
        margin: 0;
      }
      .small_title{
        font-family: Montserrat arm;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        margin-top: 20px;
        padding-right: 40px;
      }

    }
  }

  .row-for-tab-lists-mobile{
    display: none;
    padding-left: 0;
    padding-right: 0;
    @media screen and (max-width: 778px){
      display: block;
    }
    @media screen and (max-width: 605px){
      padding-left: 5px;
      padding-right: 5px;
    }
    .react-tabs__tab-list{
      background: transparent !important;
      display: flex;
      justify-content:space-around !important;
      margin-top: 20px !important;
      border-bottom: none;
      padding-left: 0 !important;
      .react-tabs__tab{
        width: 32%;
        height: 35px;
        border: none;
        background: #FFFFFF;
        box-shadow: 3px 5px 17px #DADADA;
        border-radius: 10px;
        font-family:$fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.015em;
        color: #494949;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }
    .react-tabs__tab--selected{
      background: #73B3F2 !important;
      box-shadow: 3px 5px 17px #DADADA;
      border-radius: 10px;
      color: #FFFFFF !important;
    }
  }


  .row-for-tab-lists{
    @media screen and (max-width: 778px){
      display: none;
    }
    @media screen and (max-width:600px){
      padding-left: 5px;
      padding-right: 5px;
    }


    .react-tabs__tab-list{
      background: #f1f1f1 !important;
      display: flex !important;
      justify-content: space-between;
      margin-top: 20px !important;
      border-bottom: none;
      padding-left: 20px;
      padding-right: 20px;
      @media screen and (max-width:600px){
        justify-content: space-between;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .react-tabs__tab{
        top: 0;
        background: transparent;
        //box-shadow: -1px 4px 9px 1px #C4C4C4;
        border-radius: 10px;
        width: 218.76px !important;
        height: 100.75px;
        font-family:'Montserrat' , sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #494949;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 992px){
          width:140px !important;
        }
        @media screen and (max-width: 600px) {
          width: 140px !important;
          height:34px;
          background: #FFFFFF;
          box-shadow: 3px 5px 17px #DADADA;
          border-radius: 10px;
          font-family:'Montserrat' , sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: -0.015em;
          color: #494949;
          margin-left: 0;
        }
        @media screen and (max-width: 350px) {
          width:88px !important;
          font-size: 12px;
        }
        &:nth-child(1){
          img{
            width: 119.34px;
            height: 78.74px;
            // filter: brightness(20);
          }
        }
        &:nth-child(2){
          img{
            width: 127.56px;
            height: 72.25px;
            // filter: brightness(0);
          }
        }
        &:nth-child(3){
          img{
            width: 101.76px;
            height: 60.15px;
            // filter: brightness(20%);
          }
        }
      }
      #react-tabs-1{
        padding-bottom: 0px  !important;

      }
      .react-tabs__tab--selected{
        background: #fff;
        border-radius: 10px;
        padding-bottom: 0px !important;
        font-family:'Montserrat' , sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #FEFEFE;
        position: relative;
        @media screen and (max-width: 600px) {
          font-family:'Montserrat' , sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
        }
        &::after{
          position: absolute;
          content: "";
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
          background: #FFFFFF;
          width: 62px;
          height:35px;
          transform: rotate(180deg);
          bottom: -35px;
          left: 78px;
        }
        border: 1px solid #F2F2F2;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px #DADADA;
      }
    }
  }


  // .left-side-nav{
  //   .dropdaowns-menu{

  //     &:hover{
  //       background: rgba(115, 179, 242, 1);
  //     }
  //     .dropdown-item:active{
  //       background: transparent !important;

  //     }
  //     .nav-item:hover a{
  //       color: #fff !important;
  //     }
  //     .show{
  //       background: rgba(115, 179, 242, 1);
  //       color: #fff;
  //       &:hover{
  //         color: #fff !important;
  //       }
  //       a{
  //         padding: 25px 20px;

  //         font-family: $fontFamily;
  //         font-style: normal;
  //         font-weight: normal;
  //         font-size: 16px;
  //         line-height: 20px;
  //         letter-spacing: -0.015em;
  //         transition: all .5s linear;
  //         border-bottom: 1px solid rgba(229, 229, 229, 1);
  //         &:hover{
  //           color: #fff !important;
  //           background:#00386A;
  //         }
  //       }
  //     }
  //   }
  //   .active{
  //     transition: all .3s linear;
  //     background: transparent;

  //     &:hover{
  //       background: #F2F2F2;
  //     }

  //   }
  //   .nav-item{
  //     transition: all .3s linear;
  //   }
  //   .dropdown-menu{
  //     width: 100%;
  //     &:hover {
  //       background: #73B3F2 !important;
  //     }
  //   }
  // }

}
.for_border_bottom{
  @media screen and (max-width: 600px) {
    border:1px solid #DADADA;
    padding: 0;
    margin:30px 0;
  }
}
.row_for_napkins_img{
  padding-top: 30px;
  @media screen and (max-width:600px){
    padding: 0 15px;
    display: flex;
    background: #FFFFFF;
  }
  .col-for-product-card{
    @media screen and (max-width: 600px) {
      padding-left: 5px !important;
      padding-right: 5px !important;
      //border: 1px solid red;
      position: relative;
    }
    .section_for_tab_content{
      background: #FFFFFF;
      border: 1px solid #F2F2F2;
      box-sizing: border-box;
      box-shadow: 0px 0px 9px #F1F1F1;
      position: relative;
      width: 100%;
      @media screen and (max-width:600px){
        border: 1px solid #F2F2F2;
        box-sizing: border-box;
        box-shadow: 0px 0px 9px #F1F1F1;
        width: 100%;
        //height: 100%;
      }
      .for_tab_content_img{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top:40px;
        padding-bottom: 40px;
        padding-left: 40px;
        padding-right: 40px;
        //width: 270px;
        height: 205px;
        border-bottom: 2px solid #F2F2F2;
        cursor: pointer;
        //position:relative;

        @media screen and (max-width: 1200px) {
          padding-top:20px;
          padding-bottom: 20px;
          padding-left: 20px;
          padding-right: 20px;
          //height: 160px;
        }
        @media screen and (max-width: 992px) {
          height: 170px;
        }
        @media screen and (max-width: 600px) {
          padding-top:20px;
          padding-bottom: 20px;
          padding-left: 15px;
          padding-right: 15px;
          height: 190px;
        }
        @media screen and (max-width: 375px) {
          padding-top:20px;
          padding-bottom: 20px;
          padding-left: 15px;
          padding-right: 15px;
          height: 150px;
        }
        .for_desc{
          position: absolute;
          width: 100%;
          bottom: 1px;
          height: 30px;
          //color:#FFFFFF;
          opacity: 1;
          color: #494949;
          background: #F1F1F1;
          font-family: $fontFamily;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          letter-spacing: -0.015em;
          @media screen and (max-width: 600px){
              font-size: 14px;
          }
        }
        //img{
        //  width: 100%;
        //  height: 100%;
        //}
      }

      .for_tab_content_text{
        padding: 10px 10px;
        max-height: 110px;
        @media screen and (max-width: 550px){
          padding: 10px 10px !important;
        }
        .tab-content-title{
          font-family: $fontFamily;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 10px;
          display: flex;
          align-items: center;
          letter-spacing: -0.015em;
          color: #494949;
          @media screen and (max-width: 1200px){
              font-size: 14px;
          }
          @media screen and (max-width: 414px){
              font-size: 12px;
              line-height: 14px;
          }
        }
        .tab-content-desc-size{
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 10px;
          display: flex;
          align-items: center;
          letter-spacing: -0.015em;
          color: #494949;
          @media screen and (max-width: 1200px){
            font-size: 14px;
          }

          @media screen and (max-width: 414px){
            font-size: 12px;
          }
        }
        .tab-content-desc-articule{
          font-family: $fontFamily;
          font-style: normal;
          font-weight: bold;
          font-size: 17px;
          line-height: 0px;
          //display: flex;
          //align-items: center;
          letter-spacing: -0.015em;
          color: #494949;
          border-left: 1px solid #494949;
          width: fit-content;
          padding: 20px 12px;
          float: right;
          position: relative;
          bottom: 55px;
          @media screen and (max-width: 1200px){
              padding:10px 8px ;
              font-size: 14px;
            bottom: 42px;
          }
          @media screen and (max-width: 550px){
              padding-right: 0;
          }
          //
          //@media screen and (max-width: 414px){
          //  font-size: 12px;
          //}
          @media screen and (max-width: 440px) {
           position: unset;
           float: left;
            border: none;
            padding: 0;
          }
        }
        @media screen and (max-width:600px){
          font-family:$fontFamily;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: -0.015em;
          color: #494949;
          padding:15px 30px;
        }
        @media screen and (max-width:400px){
          padding:15px 10px;

        }
      }
    }

  }

}
//#cf {
//  position:relative;
//  //height:281px;
//  //width:450px;
//  margin:0 auto;
//  width: 205px;
//  border: 1px solid red;
//  height: 135.4px;
//  display: flex;
//  justify-content: center !important;
//  @media screen and (max-width: 414px){
//    height: 120px;
//  }
//  @media screen and (max-width: 375px){
//    height: 105px;
//  }
//  @media screen and (max-width: 320px){
//    height: 80px;
//  }
//}
//
//#cf img {
//  position:absolute;
//  left:0;
//  top: 0;
//  -webkit-transition: opacity .6s ease-in-out;
//  -moz-transition: opacity .6s ease-in-out;
//  -o-transition: opacity .6s ease-in-out;
//  transition: opacity .6s ease-in-out;
//  //width: auto;
//  //height: 100%;
//
//}

#cf img.top:hover {
  opacity:0;
}
.modal-body{
  position:relative !important;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  .close_btn{
    background: transparent;
    border: none;
    outline: none;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.54);
  }
  .full_section{
    padding-top: 50px;
    padding-bottom: 60px;
    .slick-slide{
      width: 100%;
      padding: 60px;
    }
    .img-card{
      display: flex !important;
      justify-content: center !important;
      padding:10px 30px;
    }
    .slick-prev{
      display:none !important;
    }
    .slick-next{
      display:none !important;
    }
    .slick-dots{
      position:relative;
      top: 20px !important;
      li{
        ::before{
          color: #00386A;
          font-size: 17px !important;
        }
      }
      .slick-active{
        ::before{
          color: #8E1699;
          font-size: 17px !important;
        }
      }
    }

  }
}

#articul-bottom{
  position: unset;
  float: none;
  border: 1px solid #494949;
  padding: 10px 8px;
}

#tab-computer-size{
  display: block;
  @media screen and (max-width: 778px){
    display: none;
  }
  .dropdaowns-menu{
    border-bottom: 1px solid #E5E5E5;
    box-sizing: border-box;
    .nav-item{
      a{
          transition:all .5s linear;
          font-family:$fontFamily;
          font-style: normal;
          font-size: 16px;
          font-weight: normal;
          display: flex;
          line-height: 20px;
          align-items: center;
          letter-spacing: -0.015em;
          color: #494949;
          padding: 25px 20px;
          &::after{
            font-size: 16px;
            color: #494949;
            position: absolute;
            right: 20px;
          }          
        &:hover{
          background-color:#73B3F2;
        }
      }
      .dropdown-menu{
        padding:0;
        width: 100%;
        .dropdown-item{
          border-bottom: 1px solid #E5E5E5;
        }
      }
    }
    .dropdown-item{
      transition:all .5s linear;
      font-family:$fontFamily;
      font-style: normal;
      font-size: 16px;
      font-weight: normal;
      display: flex;
      line-height: 20px;
      align-items: center;
      letter-spacing: -0.015em;
      color: #494949;
      padding: 25px 20px;
      &::after{
        font-size: 16px;
        color: #494949;
        position: absolute;
        right: 20px;
      }          
        &:hover{
        background-color:#73B3F2;
      }
    }
  }
}

  #tab-mobile-size{
      display: none;
      @media screen and (max-width: 778px){
        display: block !important;
        background:#ffffff;
        box-shadow: 3px 5px 17px #DADADA;
        border-radius: 10px;
        
        .nav-item{
          #left-tabs-tab-null{
            font-family: $fontFamily;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: -0.015em;
            color: #494949; 
            &::after{
              position: absolute;
              right: 20px;
            }
            
          }
          .dropdown-menu{
            width: 100%;
            padding:0;
            border-radius: 10px;
            .nav-item{
              a{
                  transition:all .5s linear;
                  font-family:$fontFamily;
                  font-style: normal;
                  font-size: 16px;
                  font-weight: normal;
                  display: flex;
                  line-height: 20px;
                  align-items: center;
                  letter-spacing: -0.015em;
                  color: #494949;
                  padding: 10px 20px;
                  border-bottom: 1px solid #E5E5E5;
                  &::after{
                    font-size: 16px;
                    color: #494949;
                    position: absolute;
                    right: 20px;
                  }          
                &:hover{
                  background-color:#73B3F2;
                }
              }
              .dropdown-menu{
                padding:0;
                width: 100%;
                // .dropdown-item{
                // }
                .dropdown-item{
                  border-bottom: 1px solid #E5E5E5;
                  transition:all .5s linear;
                  font-family:$fontFamily;
                  font-style: normal;
                  font-size: 13px;
                  font-weight: normal;
                  display: flex;
                  line-height: 20px;
                  align-items: center;
                  letter-spacing: -0.015em;
                  color: #494949;
                  padding: 10px 20px;
                  &::after{
                    font-size: 16px;
                    color: #494949;
                    position: absolute;
                    right: 20px;
                  }          
                    &:hover{
                    background-color:#73B3F2;
                  }
                }
              }
              .dropdown-item{
                border-bottom: 1px solid #E5E5E5;
                transition:all .5s linear;
                font-family:$fontFamily;
                font-style: normal;
                font-size: 13px;
                font-weight: normal;
                display: flex;
                line-height: 20px;
                align-items: center;
                letter-spacing: -0.015em;
                color: #494949;
                padding: 10px 20px;
                &::after{
                  font-size: 16px;
                  color: #494949;
                  position: absolute;
                  right: 20px;
                }          
                  &:hover{
                  background-color:#73B3F2;
                }
              }
            }   
          }
        }
      }
    }

.icon-description{
  //border:1px solid red;
  display: flex;
  justify-content: space-around;

  .icon-description-small{
    display: flex;
    justify-content:space-evenly;
    width: 33%;
    border-right: 1px solid #005cbf;
    .count{
      color: #005cbf;
      font-family: $fontFamily;
      font-weight: 800;
    }
  }
  .text{
    color: #005cbf;
    font-family: $fontFamily;
    font-weight: 600;
  }
}

