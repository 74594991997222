.ReactModal__Content--after-open{
  padding-bottom: 0 !important;
}
.ReactModal__Content{
  width: 40%;
  height: fit-content !important;
  margin: auto;
  padding: 0;
  opacity: 1;
  background: #fff !important;
  //max-height: 80%;
  @media screen and (max-width: 1200px){
      width: 50%;
  }
  @media screen and (max-width: 900px){
      width: 60%;
  }
  @media screen and (max-width: 800px){
      width: 70%;
  }
  @media screen and (max-width: 700px){
      width: 80%;
  }
  @media screen and (max-width: 600px){
      width: 80%;
  }
  .axis-horizontal{
    width: 70%;
    height: 50% !important;
    @media screen and (max-width: 600px){
        width: 100%;
    }
    .slider{
      .slide{
        div{
          padding: 40px;
        }
      }
    }
  }
  .thumbs{
    padding-left: 0;
    .selected{
      border: 1px solid #E5E5E5 !important;
    }
    .thumb{
      width: fit-content;
      height: fit-content;
      padding: 5px;
    }
    .thumb:hover{
      border: 1px solid #f2f2f2 !important;
    }
  }
}
.control-dots{
  li{
    border: none !important;
    box-shadow: none !important;
    opacity: 1 !important;
    transform: scale(1.5);
    background: #F4F4F4 !important;
  }
  .selected{
    transform: scale(2);
  }
}
