.ReactModal__Content--after-open {
  padding-bottom: 0 !important;
}

.ReactModal__Content {
  width: 40%;
  height: fit-content !important;
  margin: auto;
  padding: 0;
  opacity: 1;
  background: #fff !important;
}
@media screen and (max-width: 1200px) {
  .ReactModal__Content {
    width: 50%;
  }
}
@media screen and (max-width: 900px) {
  .ReactModal__Content {
    width: 60%;
  }
}
@media screen and (max-width: 800px) {
  .ReactModal__Content {
    width: 70%;
  }
}
@media screen and (max-width: 700px) {
  .ReactModal__Content {
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  .ReactModal__Content {
    width: 80%;
  }
}
.ReactModal__Content .axis-horizontal {
  width: 70%;
  height: 50% !important;
}
@media screen and (max-width: 600px) {
  .ReactModal__Content .axis-horizontal {
    width: 100%;
  }
}
.ReactModal__Content .axis-horizontal .slider .slide div {
  padding: 40px;
}
.ReactModal__Content .thumbs {
  padding-left: 0;
}
.ReactModal__Content .thumbs .selected {
  border: 1px solid #E5E5E5 !important;
}
.ReactModal__Content .thumbs .thumb {
  width: fit-content;
  height: fit-content;
  padding: 5px;
}
.ReactModal__Content .thumbs .thumb:hover {
  border: 1px solid #f2f2f2 !important;
}

.control-dots li {
  border: none !important;
  box-shadow: none !important;
  opacity: 1 !important;
  transform: scale(1.5);
  background: #F4F4F4 !important;
}
.control-dots .selected {
  transform: scale(2);
}

