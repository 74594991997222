@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap");
.first_career_section {
  margin-top: 50px;
}
@media screen and (max-width: 600px) {
  .first_career_section {
    margin-top: 0px;
  }
}
.first_career_section .career_first_title {
  padding-top: 30px;
  padding-bottom: 50px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 600px) {
  .first_career_section .career_first_title {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 20px;
  }
}
.first_career_section .career_first_desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #000000;
  padding: 0px 0px;
  text-align: justify;
}
@media screen and (max-width: 900px) {
  .first_career_section .career_first_desc {
    padding: 0px 40px;
  }
}
@media screen and (max-width: 600px) {
  .first_career_section .career_first_desc {
    font-size: 14px;
    line-height: 17px;
  }
}

.second_career_section {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 60px;
}
@media screen and (max-width: 600px) {
  .second_career_section {
    margin-top: 0;
    padding-bottom: 30px;
  }
}
.second_career_section .second_career_section_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #000000;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media screen and (max-width: 600px) {
  .second_career_section .second_career_section_title {
    padding-top: 40px;
    padding-bottom: 0px;
    font-size: 16px;
  }
}
.second_career_section .div_for_carer_img_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.second_career_section .div_for_carer_img_section .div_for_carer_text_section {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #000000;
}
.second_career_section .section_for_jobs .div_for_jobs {
  padding-bottom: 20px;
  background: #F1F1F1;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0px 0px 14px #C4C4C4;
}
.second_career_section .section_for_jobs .div_for_jobs .for_carer_img {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 350px) {
  .second_career_section .section_for_jobs .div_for_jobs .for_carer_img {
    padding: 0px 20px;
  }
}
.second_career_section .section_for_jobs .div_for_jobs .for_carer_desc {
  padding-top: 25px;
  padding-bottom: 25px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.015em;
  color: #000000;
}
@media screen and (max-width: 600px) {
  .second_career_section .section_for_jobs .div_for_jobs .for_carer_desc {
    font-size: 13px;
    line-height: 15.85px;
    font-weight: 400;
    text-align: center;
  }
}
@media screen and (max-width: 350px) {
  .second_career_section .section_for_jobs .div_for_jobs .for_carer_desc {
    padding-top: 15px;
  }
}
.second_career_section .section_for_jobs .div_for_jobs .for_carer_btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.second_career_section .section_for_jobs .div_for_jobs .for_carer_btn .button_for_apply {
  width: 160px;
  height: 48px;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px #C4C4C4;
  border-radius: 50px;
  border: none;
  outline: none;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}
.second_career_section .section_for_jobs .div_for_jobs .for_carer_btn .button_for_apply:hover {
  background: #73b3f2;
}
@media screen and (max-width: 500px) {
  .second_career_section .section_for_jobs .div_for_jobs .for_carer_btn .button_for_apply {
    width: 101.64px;
    height: 43px;
  }
}

.modal-dialog .modal-content {
  width: 100%;
}
.modal-dialog .modal-content .row_for_form {
  padding-top: 20px;
  padding-bottom: 30px;
}
.modal-dialog .modal-content .row_for_form .form-label {
  padding-left: 15px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #494949;
}
.modal-dialog .modal-content .row_for_form .form-control::placeholder {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #C4C4C4;
  padding-left: 2px;
}
.modal-dialog .modal-content .modal_title {
  padding-top: 30px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #494949;
}
.modal-dialog .modal-content .for_sbm_and_cv .right_btn_cbm {
  border: none !important;
  outline: none !important;
  background: #DC6B33 !important;
  float: right;
  width: 40%;
  height: 43px;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
.modal-dialog .modal-content .for_sbm_and_cv .left_btn_cv {
  border: none !important;
  outline: none !important;
  background: #73B3F2 !important;
  width: 40%;
  height: 43px;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}
.ReactModal__Overlay--after-open .ReactModal__Content {
  height: 80% !important;
}
.ReactModal__Overlay--after-open .ReactModal__Content--after-open {
  opacity: 1 !important;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}

.ReactModal__Content--after-open {
  opacity: 1;
}

.ReactModal__Content, .ReactModal__Content--after-open {
  height: 80% !important;
}

.ReactModal__Content {
  background: #F2F2F2 !important;
  height: 300px;
  overflow-y: scroll !important;
}
.ReactModal__Content .close_btn {
  border: none !important;
  background: transparent !important;
  color: #494949;
  font-size: 24px;
}
.ReactModal__Content .row_for_form {
  padding-top: 20px;
  padding-bottom: 30px;
}
.ReactModal__Content .row_for_form .form-label {
  padding-left: 15px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #494949;
}
.ReactModal__Content .row_for_form #for_education {
  border: 1px solid #C4C4C4 !important;
}
.ReactModal__Content .row_for_form .form-control::placeholder {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #C4C4C4;
  padding-left: 2px;
}
.ReactModal__Content .modal_title {
  padding-top: 30px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #494949;
}
.ReactModal__Content .for_sbm_and_cv .right_btn_cbm {
  border: none !important;
  outline: none !important;
  background: #DC6B33 !important;
  float: right;
  width: 40%;
  height: 43px;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
.ReactModal__Content .for_sbm_and_cv .left_btn_cv {
  border: none !important;
  outline: none !important;
  background: #73B3F2 !important;
  width: 40%;
  height: 43px;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}

.close_btn {
  border: none !important;
  background: transparent !important;
  color: #494949;
  font-size: 24px;
}

