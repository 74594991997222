@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap");
$fontFamily:'Montserrat' , sans-serif;

.first_career_section{
  margin-top: 50px;
  @media screen and (max-width:600px){
    margin-top: 0px;

  }
  .career_first_title{
    padding-top: 30px;
    padding-bottom:50px;
    font-family:$fontFamily;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #494949;
    @media screen and (max-width:600px){
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 20px;

    }
  }
  .career_first_desc{
    font-family:$fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 0px 0px;
    text-align: justify;
    @media screen and (max-width:900px){
      padding: 0px 40px;
    }
    @media screen and (max-width:600px){
      font-size: 14px;
      line-height: 17px;
    }
  }
}
.second_career_section{
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 60px;
  @media screen and (max-width:600px){
    margin-top: 0;
    padding-bottom: 30px;
  }
  .second_career_section_title{
    font-family:$fontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-top: 40px;
    padding-bottom: 40px;
    @media screen and (max-width:600px){
      padding-top: 40px;
      padding-bottom: 0px;
      font-size: 16px;
    }
  }
  .div_for_carer_img_section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .div_for_carer_text_section{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #000000;
    }
  }
  .section_for_jobs{
    .div_for_jobs{
      // padding-top:35px;
      padding-bottom:20px;
      background: #F1F1F1;
      height:100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      box-shadow: 0px 0px 14px #C4C4C4;
      .for_carer_img{
        display:flex;
        justify-content:center ;
        align-items: center;
        @media screen and (max-width: 350px) {
          padding:0px 20px;
        }
      }
      .for_carer_desc{
        padding-top:25px;
        padding-bottom:25px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        justify-content:center;
        align-items: center;
        letter-spacing: -0.015em;
        color: #000000;
        @media screen and (max-width:600px){
          font-size: 13px;
          line-height: 15.85px;
          font-weight: 400;
          text-align:center;

        }
        @media screen and (max-width: 350px) {
          padding-top:15px;
        }
      }
      .for_carer_btn{
        margin-top: 20px;
        display: flex;
        justify-content: center ;
        .button_for_apply{
          width: 160px;
          height: 48px;
          background: #FFFFFF;
          box-shadow: 0px 2px 7px #C4C4C4;
          border-radius: 50px;
          border: none;
          outline: none;
          transition: all .3s linear;
          -webkit-transition: all .3s linear;
          -o-transition: all .3s linear;
          &:hover{
            background: rgba(115, 179, 242, 1);
          }
          @media screen and (max-width:500px){
            width: 101.64px;
            height: 43px;
          }
        }
      }

    }

  }
}
//.ReactModal__Content{
//  background:#F2F2F2 !important;
//  height: 300px;
//  overflow: scroll !important;
//  .row_for_form{
//    padding-top: 20px;
//    padding-bottom: 30px;
//    .form-label{
//      padding-left: 15px;
//      font-family: $fontFamily;
//      font-style: normal;
//      font-weight: 500;
//      font-size: 14px;
//      line-height: 17px;
//      letter-spacing: -0.015em;
//      color: #494949;
//    }
//
//    .form-control::placeholder{
//      font-family: $fontFamily;
//      font-style: normal;
//      font-weight: normal;
//      font-size: 14px;
//      line-height: 17px;
//      letter-spacing: -0.015em;
//      color: #C4C4C4;
//      padding-left: 2px;
//    }
//  }
//  .modal_title{
//    padding-top: 30px;
//    font-family:$fontFamily;
//    font-style: normal;
//    font-weight: normal;
//    font-size: 18px;
//    line-height: 22px;
//    text-align: center;
//    letter-spacing: -0.015em;
//    color: #494949;
//  }
//  .for_sbm_and_cv{
//    //padding-bottom: 30px;
//    .right_btn_cbm{
//      border: none !important;
//      outline: none !important;
//      background: #DC6B33 !important;
//      float: right;
//      width: 40%;
//      height: 43px;
//      border-radius: 20px;
//      font-family: $fontFamily;
//      font-style: normal;
//      font-weight: bold;
//      font-size: 14px;
//      line-height: 17px;
//      display: flex;
//      align-items: center;
//      justify-content: center;
//      letter-spacing: -0.015em;
//      color: #FFFFFF;
//    }
//    .left_btn_cv{
//      border: none !important;
//      outline: none !important;
//      background:#73B3F2  !important;
//      width: 40%;
//      height: 43px;
//      border-radius: 20px;
//      font-family: $fontFamily;
//      font-style: normal;
//      font-weight: bold;
//      font-size: 14px;
//      line-height: 17px;
//      display: flex;
//      align-items: center;
//      justify-content: center;
//      letter-spacing: -0.015em;
//      color: #FFFFFF;
//    }
//  }
//}
.modal-dialog{

  .modal-content{
    width: 100%;
    .row_for_form{
      padding-top: 20px;
      padding-bottom: 30px;
      .form-label{
        padding-left: 15px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.015em;
        color: #494949;
      }

      .form-control::placeholder{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.015em;
        color: #C4C4C4;
        padding-left: 2px;
      }
    }
    .modal_title{
      padding-top: 30px;
      font-family:$fontFamily;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #494949;
    }
    .for_sbm_and_cv{
      //padding-bottom: 30px;
      .right_btn_cbm{
        border: none !important;
        outline: none !important;
        background: #DC6B33 !important;
        float: right;
        width: 40%;
        height: 43px;
        border-radius: 20px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -0.015em;
        color: #FFFFFF;
      }
      .left_btn_cv{
        border: none !important;
        outline: none !important;
        background:#73B3F2  !important;
        width: 40%;
        height: 43px;
        border-radius: 20px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -0.015em;
        color: #FFFFFF;
      }
    }
  }

}
//#mod{
//  background: red !important;
//  overflow: visible !important;
//  div{
//    top: 0;
//    background: blue;
//    overflow-y: auto !important;
//
//  }
//  .row_for_modal_title{
//  }
//}




.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;

}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
  .ReactModal__Content{
      height: 80% !important;
   }
  .ReactModal__Content--after-open{
    opacity: 1 !important;

  }
  }


.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}
.ReactModal__Content--after-open{
  opacity: 1;
}
.ReactModal__Content ,.ReactModal__Content--after-open{
  height: 80% !important;

}
.ReactModal__Content{
    background:#F2F2F2 !important;
    height: 300px;
    overflow-y: scroll !important;

  .close_btn{
    border: none !important;
    background: transparent !important;
    color: #494949;
    font-size: 24px;
  }
    .row_for_form{
      padding-top: 20px;
      padding-bottom: 30px;
      .form-label{
        padding-left: 15px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.015em;
        color: #494949;
      }
      #for_education{
        border: 1px solid #C4C4C4 !important;
      }
      .form-control::placeholder{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.015em;
        color: #C4C4C4;
        padding-left: 2px;
      }
    }
    .modal_title{
      padding-top: 30px;
      font-family:$fontFamily;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #494949;
    }
    .for_sbm_and_cv{
      //padding-bottom: 30px;
      .right_btn_cbm{
        border: none !important;
        outline: none !important;
        background: #DC6B33 !important;
        float: right;
        width: 40%;
        height: 43px;
        border-radius: 20px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -0.015em;
        color: #FFFFFF;
      }
      .left_btn_cv{
        border: none !important;
        outline: none !important;
        background:#73B3F2  !important;
        width: 40%;
        height: 43px;
        border-radius: 20px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -0.015em;
        color: #FFFFFF;
      }
    }
}
.close_btn{
  border: none !important;
  background: transparent !important;
  color: #494949;
  font-size: 24px;
}