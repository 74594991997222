@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
#product-first-section {
  background: #fff;
  padding-top: 30px;
  padding-bottom: 60px;
}
@media screen and (max-width: 600px) {
  #product-first-section {
    background: #fff !important;
  }
}
#product-first-section .left_content_for_height_bg {
  background: white;
  padding: 0;
  box-shadow: 2px 2px 12px #C4C4C4;
  height: fit-content;
}
@media screen and (max-width: 600px) {
  #product-first-section .left_content_for_height_bg {
    background: none;
    padding: 0 5px;
    box-shadow: none;
  }
}
#product-first-section .left_content_for_height_bg #tab-mobile-size {
  display: none;
}
#product-first-section .react-tabs__tab-panel {
  padding-bottom: 0;
}
#product-first-section .section_for_wet_napkins {
  background: #E0A818;
  height: 700px;
}
#product-first-section .section_for_wet_napkins .wet_napkins_text {
  padding: 40px 20px;
}
#product-first-section .section_for_wet_napkins .wet_napkins_text .big_title {
  font-family: Montserrat arm;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  padding: 0;
  margin: 0;
}
#product-first-section .section_for_wet_napkins .wet_napkins_text .small_title {
  font-family: Montserrat arm;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  margin-top: 20px;
  padding-right: 40px;
}
#product-first-section .row-for-tab-lists-mobile {
  display: none;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 778px) {
  #product-first-section .row-for-tab-lists-mobile {
    display: block;
  }
}
@media screen and (max-width: 605px) {
  #product-first-section .row-for-tab-lists-mobile {
    padding-left: 5px;
    padding-right: 5px;
  }
}
#product-first-section .row-for-tab-lists-mobile .react-tabs__tab-list {
  background: transparent !important;
  display: flex;
  justify-content: space-around !important;
  margin-top: 20px !important;
  border-bottom: none;
  padding-left: 0 !important;
}
#product-first-section .row-for-tab-lists-mobile .react-tabs__tab-list .react-tabs__tab {
  width: 32%;
  height: 35px;
  border: none;
  background: #FFFFFF;
  box-shadow: 3px 5px 17px #DADADA;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: #494949;
  display: flex;
  justify-content: center;
  align-items: center;
}
#product-first-section .row-for-tab-lists-mobile .react-tabs__tab--selected {
  background: #73B3F2 !important;
  box-shadow: 3px 5px 17px #DADADA;
  border-radius: 10px;
  color: #FFFFFF !important;
}
@media screen and (max-width: 778px) {
  #product-first-section .row-for-tab-lists {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  #product-first-section .row-for-tab-lists {
    padding-left: 5px;
    padding-right: 5px;
  }
}
#product-first-section .row-for-tab-lists .react-tabs__tab-list {
  background: #f1f1f1 !important;
  display: flex !important;
  justify-content: space-between;
  margin-top: 20px !important;
  border-bottom: none;
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (max-width: 600px) {
  #product-first-section .row-for-tab-lists .react-tabs__tab-list {
    justify-content: space-between;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
#product-first-section .row-for-tab-lists .react-tabs__tab-list .react-tabs__tab {
  top: 0;
  background: transparent;
  border-radius: 10px;
  width: 218.76px !important;
  height: 100.75px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #494949;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 992px) {
  #product-first-section .row-for-tab-lists .react-tabs__tab-list .react-tabs__tab {
    width: 140px !important;
  }
}
@media screen and (max-width: 600px) {
  #product-first-section .row-for-tab-lists .react-tabs__tab-list .react-tabs__tab {
    width: 140px !important;
    height: 34px;
    background: #FFFFFF;
    box-shadow: 3px 5px 17px #DADADA;
    border-radius: 10px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #494949;
    margin-left: 0;
  }
}
@media screen and (max-width: 350px) {
  #product-first-section .row-for-tab-lists .react-tabs__tab-list .react-tabs__tab {
    width: 88px !important;
    font-size: 12px;
  }
}
#product-first-section .row-for-tab-lists .react-tabs__tab-list .react-tabs__tab:nth-child(1) img {
  width: 119.34px;
  height: 78.74px;
}
#product-first-section .row-for-tab-lists .react-tabs__tab-list .react-tabs__tab:nth-child(2) img {
  width: 127.56px;
  height: 72.25px;
}
#product-first-section .row-for-tab-lists .react-tabs__tab-list .react-tabs__tab:nth-child(3) img {
  width: 101.76px;
  height: 60.15px;
}
#product-first-section .row-for-tab-lists .react-tabs__tab-list #react-tabs-1 {
  padding-bottom: 0px !important;
}
#product-first-section .row-for-tab-lists .react-tabs__tab-list .react-tabs__tab--selected {
  background: #fff;
  border-radius: 10px;
  padding-bottom: 0px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #FEFEFE;
  position: relative;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px #DADADA;
}
@media screen and (max-width: 600px) {
  #product-first-section .row-for-tab-lists .react-tabs__tab-list .react-tabs__tab--selected {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
  }
}
#product-first-section .row-for-tab-lists .react-tabs__tab-list .react-tabs__tab--selected::after {
  position: absolute;
  content: "";
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: #FFFFFF;
  width: 62px;
  height: 35px;
  transform: rotate(180deg);
  bottom: -35px;
  left: 78px;
}

@media screen and (max-width: 600px) {
  .for_border_bottom {
    border: 1px solid #DADADA;
    padding: 0;
    margin: 30px 0;
  }
}

.row_for_napkins_img {
  padding-top: 30px;
}
@media screen and (max-width: 600px) {
  .row_for_napkins_img {
    padding: 0 15px;
    display: flex;
    background: #FFFFFF;
  }
}
@media screen and (max-width: 600px) {
  .row_for_napkins_img .col-for-product-card {
    padding-left: 5px !important;
    padding-right: 5px !important;
    position: relative;
  }
}
.row_for_napkins_img .col-for-product-card .section_for_tab_content {
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0px 0px 9px #F1F1F1;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content {
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    box-shadow: 0px 0px 9px #F1F1F1;
    width: 100%;
  }
}
.row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  height: 205px;
  border-bottom: 2px solid #F2F2F2;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_img {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 992px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_img {
    height: 170px;
  }
}
@media screen and (max-width: 600px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_img {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    height: 190px;
  }
}
@media screen and (max-width: 375px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_img {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    height: 150px;
  }
}
.row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_img .for_desc {
  position: absolute;
  width: 100%;
  bottom: 1px;
  height: 30px;
  opacity: 1;
  color: #494949;
  background: #F1F1F1;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.015em;
}
@media screen and (max-width: 600px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_img .for_desc {
    font-size: 14px;
  }
}
.row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text {
  padding: 10px 10px;
  max-height: 110px;
}
@media screen and (max-width: 550px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text {
    padding: 10px 10px !important;
  }
}
.row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text .tab-content-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 10px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 1200px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text .tab-content-title {
    font-size: 14px;
  }
}
@media screen and (max-width: 414px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text .tab-content-title {
    font-size: 12px;
    line-height: 14px;
  }
}
.row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text .tab-content-desc-size {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 10px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 1200px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text .tab-content-desc-size {
    font-size: 14px;
  }
}
@media screen and (max-width: 414px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text .tab-content-desc-size {
    font-size: 12px;
  }
}
.row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text .tab-content-desc-articule {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 0px;
  letter-spacing: -0.015em;
  color: #494949;
  border-left: 1px solid #494949;
  width: fit-content;
  padding: 20px 12px;
  float: right;
  position: relative;
  bottom: 55px;
}
@media screen and (max-width: 1200px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text .tab-content-desc-articule {
    padding: 10px 8px;
    font-size: 14px;
    bottom: 42px;
  }
}
@media screen and (max-width: 550px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text .tab-content-desc-articule {
    padding-right: 0;
  }
}
@media screen and (max-width: 440px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text .tab-content-desc-articule {
    position: unset;
    float: left;
    border: none;
    padding: 0;
  }
}
@media screen and (max-width: 600px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.015em;
    color: #494949;
    padding: 15px 30px;
  }
}
@media screen and (max-width: 400px) {
  .row_for_napkins_img .col-for-product-card .section_for_tab_content .for_tab_content_text {
    padding: 15px 10px;
  }
}

#cf img.top:hover {
  opacity: 0;
}

.modal-body {
  position: relative !important;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.modal-body .close_btn {
  background: transparent;
  border: none;
  outline: none;
  font-size: 28px;
  color: rgba(0, 0, 0, 0.54);
}
.modal-body .full_section {
  padding-top: 50px;
  padding-bottom: 60px;
}
.modal-body .full_section .slick-slide {
  width: 100%;
  padding: 60px;
}
.modal-body .full_section .img-card {
  display: flex !important;
  justify-content: center !important;
  padding: 10px 30px;
}
.modal-body .full_section .slick-prev {
  display: none !important;
}
.modal-body .full_section .slick-next {
  display: none !important;
}
.modal-body .full_section .slick-dots {
  position: relative;
  top: 20px !important;
}
.modal-body .full_section .slick-dots li ::before {
  color: #00386A;
  font-size: 17px !important;
}
.modal-body .full_section .slick-dots .slick-active ::before {
  color: #8E1699;
  font-size: 17px !important;
}

#articul-bottom {
  position: unset;
  float: none;
  border: 1px solid #494949;
  padding: 10px 8px;
}

#tab-computer-size {
  display: block;
}
@media screen and (max-width: 778px) {
  #tab-computer-size {
    display: none;
  }
}
#tab-computer-size .dropdaowns-menu {
  border-bottom: 1px solid #E5E5E5;
  box-sizing: border-box;
}
#tab-computer-size .dropdaowns-menu .nav-item a {
  transition: all 0.5s linear;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: normal;
  display: flex;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.015em;
  color: #494949;
  padding: 25px 20px;
}
#tab-computer-size .dropdaowns-menu .nav-item a::after {
  font-size: 16px;
  color: #494949;
  position: absolute;
  right: 20px;
}
#tab-computer-size .dropdaowns-menu .nav-item a:hover {
  background-color: #73B3F2;
}
#tab-computer-size .dropdaowns-menu .nav-item .dropdown-menu {
  padding: 0;
  width: 100%;
}
#tab-computer-size .dropdaowns-menu .nav-item .dropdown-menu .dropdown-item {
  border-bottom: 1px solid #E5E5E5;
}
#tab-computer-size .dropdaowns-menu .dropdown-item {
  transition: all 0.5s linear;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: normal;
  display: flex;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.015em;
  color: #494949;
  padding: 25px 20px;
}
#tab-computer-size .dropdaowns-menu .dropdown-item::after {
  font-size: 16px;
  color: #494949;
  position: absolute;
  right: 20px;
}
#tab-computer-size .dropdaowns-menu .dropdown-item:hover {
  background-color: #73B3F2;
}

#tab-mobile-size {
  display: none;
}
@media screen and (max-width: 778px) {
  #tab-mobile-size {
    display: block !important;
    background: #ffffff;
    box-shadow: 3px 5px 17px #DADADA;
    border-radius: 10px;
  }
  #tab-mobile-size .nav-item #left-tabs-tab-null {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #494949;
  }
  #tab-mobile-size .nav-item #left-tabs-tab-null::after {
    position: absolute;
    right: 20px;
  }
  #tab-mobile-size .nav-item .dropdown-menu {
    width: 100%;
    padding: 0;
    border-radius: 10px;
  }
  #tab-mobile-size .nav-item .dropdown-menu .nav-item a {
    transition: all 0.5s linear;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    display: flex;
    line-height: 20px;
    align-items: center;
    letter-spacing: -0.015em;
    color: #494949;
    padding: 10px 20px;
    border-bottom: 1px solid #E5E5E5;
  }
  #tab-mobile-size .nav-item .dropdown-menu .nav-item a::after {
    font-size: 16px;
    color: #494949;
    position: absolute;
    right: 20px;
  }
  #tab-mobile-size .nav-item .dropdown-menu .nav-item a:hover {
    background-color: #73B3F2;
  }
  #tab-mobile-size .nav-item .dropdown-menu .nav-item .dropdown-menu {
    padding: 0;
    width: 100%;
  }
  #tab-mobile-size .nav-item .dropdown-menu .nav-item .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #E5E5E5;
    transition: all 0.5s linear;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-size: 13px;
    font-weight: normal;
    display: flex;
    line-height: 20px;
    align-items: center;
    letter-spacing: -0.015em;
    color: #494949;
    padding: 10px 20px;
  }
  #tab-mobile-size .nav-item .dropdown-menu .nav-item .dropdown-menu .dropdown-item::after {
    font-size: 16px;
    color: #494949;
    position: absolute;
    right: 20px;
  }
  #tab-mobile-size .nav-item .dropdown-menu .nav-item .dropdown-menu .dropdown-item:hover {
    background-color: #73B3F2;
  }
  #tab-mobile-size .nav-item .dropdown-menu .nav-item .dropdown-item {
    border-bottom: 1px solid #E5E5E5;
    transition: all 0.5s linear;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-size: 13px;
    font-weight: normal;
    display: flex;
    line-height: 20px;
    align-items: center;
    letter-spacing: -0.015em;
    color: #494949;
    padding: 10px 20px;
  }
  #tab-mobile-size .nav-item .dropdown-menu .nav-item .dropdown-item::after {
    font-size: 16px;
    color: #494949;
    position: absolute;
    right: 20px;
  }
  #tab-mobile-size .nav-item .dropdown-menu .nav-item .dropdown-item:hover {
    background-color: #73B3F2;
  }
}

.icon-description {
  display: flex;
  justify-content: space-around;
}
.icon-description .icon-description-small {
  display: flex;
  justify-content: space-evenly;
  width: 33%;
  border-right: 1px solid #005cbf;
}
.icon-description .icon-description-small .count {
  color: #005cbf;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}
.icon-description .text {
  color: #005cbf;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

