@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.App-header {
  display: block !important;
}

.App-footer {
  display: block !important;
}

#home_page_carousel_section {
  padding-left: 0;
  padding-right: 0;
}

.home_page_about_section {
  background: #F2F2F2;
  box-shadow: 0px 0px 12px 5px #E5E5E5;
  margin-top: 100px;
}
@media screen and (max-width: 600px) {
  .home_page_about_section {
    background: #fff;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 0px 0px 0px 0px #E5E5E5;
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 600px) {
  .home_page_about_section .mobile-size-bgc {
    background: #F2F2F2;
    box-shadow: 0px 0px 12px 5px #E5E5E5;
  }
}
@media screen and (max-width: 600px) {
  .home_page_about_section {
    margin-top: 30px;
  }
}
.home_page_about_section .home_about_text {
  padding-left: 80.26px;
  padding-right: 80.26px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 992px) {
  .home_page_about_section .home_about_text {
    padding-left: 20.26px;
    padding-right: 20.26px;
  }
}
@media screen and (max-width: 778px) {
  .home_page_about_section .home_about_text {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 576px) {
  .home_page_about_section .home_about_text {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 20px;
  }
}
.home_page_about_section .home_about_text .home_about_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 600px) {
  .home_page_about_section .home_about_text .home_about_title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #494949;
  }
}
.home_page_about_section .home_about_text .home_about_desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #494949;
  text-align: justify;
}
@media screen and (max-width: 600px) {
  .home_page_about_section .home_about_text .home_about_desc {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #494949;
  }
}
.home_page_about_section .right_content {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 778px) {
  .home_page_about_section .right_content img {
    height: auto;
  }
}

.flex_end_small_contents {
  margin-top: 40px;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 600px) {
  .flex_end_small_contents {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.flex_end_small_contents .icon_contents {
  display: flex;
  background: #00386A;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .flex_end_small_contents .icon_contents {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.flex_end_small_contents .icon_contents .for_circle_icon {
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76.09px;
  height: 76.09px;
}
.flex_end_small_contents .icon_contents .for_icon_text {
  width: 229px;
  padding-left: 20px;
  padding-top: 20px;
}
.flex_end_small_contents .icon_contents .for_icon_text .for_icon_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
@media screen and (max-width: 600px) {
  .flex_end_small_contents .icon_contents .for_icon_text .for_icon_title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
  }
}
.flex_end_small_contents .icon_contents .for_icon_text .for_icon_desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
@media screen and (max-width: 600px) {
  .flex_end_small_contents .icon_contents .for_icon_text .for_icon_desc {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
  }
}
.flex_end_small_contents .icon_contents_second {
  background: #0F62AC;
}

.section_for_brands_background {
  background: #f2f2f2;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-top: 120px;
}
@media screen and (max-width: 600px) {
  .section_for_brands_background .mobile-size-bgc {
    background: #F2F2F2;
    box-shadow: 0px 0px 12px 5px #E5E5E5;
    padding-bottom: 30px;
    padding-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  .section_for_brands_background {
    background: #fff;
    margin-top: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .section_for_brands_background .section_for_brands {
    margin-top: 30px;
  }
}
.section_for_brands_background .section_for_brands .brand_text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-align: center;
  color: #494949;
}
@media screen and (max-width: 600px) {
  .section_for_brands_background .section_for_brands .brand_text {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.015em;
    color: #494949;
  }
}
.section_for_brands_background .section_for_brands .brand_small_text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #494949;
  margin-top: 30px;
}
@media screen and (max-width: 600px) {
  .section_for_brands_background .section_for_brands .brand_small_text {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #494949;
    text-align: center;
    padding: 0px 30px;
  }
}
.section_for_brands_background .section_for_brands .brand_image_row {
  margin-top: 70px;
}
@media screen and (max-width: 600px) {
  .section_for_brands_background .section_for_brands .brand_image_row {
    margin-top: 30px;
  }
}
.section_for_brands_background .section_for_brands .brand_image_row .col_for_brand {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .section_for_brands_background .section_for_brands .brand_image_row .col_for_brand {
    display: flex;
    justify-content: center;
  }
}
.section_for_brands_background .section_for_brands .brand_image_row .col_for_brand .for_brand_logo {
  background: #fff;
  width: 243px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  transition: all 0.5s linear;
  cursor: pointer;
  overflow: hidden;
}
.section_for_brands_background .section_for_brands .brand_image_row .col_for_brand .for_brand_logo:hover {
  box-shadow: 0px 4px 17px #B8B6B6;
  transition: all 0.3s linear;
}
.section_for_brands_background .section_for_brands .brand_image_row .col_for_brand .for_brand_logo:hover .for_brand_logo_text {
  transition: all 0.3s linear;
  transform: scale(1.03);
}
@media screen and (max-width: 992px) {
  .section_for_brands_background .section_for_brands .brand_image_row .col_for_brand .for_brand_logo {
    width: 230px;
  }
}
@media screen and (max-width: 767px) {
  .section_for_brands_background .section_for_brands .brand_image_row .col_for_brand .for_brand_logo {
    margin-top: 20px;
  }
}
.section_for_brands_background .section_for_brands .brand_image_row .col_for_brand .for_brand_logo .for_brand_logo_img {
  transition: all 0.5s linear;
  height: 142px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section_for_brands_background .section_for_brands .brand_image_row .col_for_brand .for_brand_logo .for_brand_logo_img img {
  max-height: 70px;
}
.section_for_brands_background .section_for_brands .brand_image_row .col_for_brand .for_brand_logo .for_brand_logo_text {
  background: #0F62AC;
  box-sizing: border-box;
  box-shadow: inset 0px 4px 17px rgba(0, 56, 106, 0.15);
  border-radius: 0px;
  padding: 20px 10px 20px 10px;
  position: relative;
  transition: all 0.3s linear;
}
.section_for_brands_background .section_for_brands .brand_image_row .col_for_brand .for_brand_logo .for_brand_logo_text::after {
  position: absolute;
  content: "";
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: #FFFFFF;
  width: 28px;
  height: 14px;
  transform: rotate(180deg);
  top: -2px;
  left: 98px;
}
.section_for_brands_background .section_for_brands .brand_image_row .col_for_brand .for_brand_logo .for_brand_logo_text .for_brand_logo_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
.section_for_brands_background .section_for_brands .brand_image_row .col_for_brand .for_brand_logo .for_brand_logo_text .for_brand_logo_desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
.section_for_brands_background .section_for_brands #link_for_see_more_small {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 171px;
  height: 43px;
  border: 1px solid #00386A;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #00386A !important;
  margin: auto;
  margin-top: 82px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.section_for_brands_background .section_for_brands #link_for_see_more_small:hover {
  background: #73B3F2 !important;
  color: #FFFFFF !important;
}

#section_for_product {
  padding-top: 60px;
  position: relative;
}
#section_for_product::after {
  position: absolute;
  content: "";
  bottom: 0;
  width: 100%;
  height: 46%;
  left: 0;
  background: #f2f2f2;
}
@media screen and (max-width: 800px) {
  #section_for_product::after {
    height: 0;
  }
}
@media screen and (max-width: 800px) {
  #section_for_product::after {
    height: 50%;
  }
}
#section_for_product #row_for_text .for_product_big_text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 600px) {
  #section_for_product #row_for_text .for_product_big_text {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #494949;
  }
}
#section_for_product #row_for_text .for_product_small_text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #494949;
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  #section_for_product #row_for_text .for_product_small_text {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #494949;
    padding: 0px 26px;
  }
}
#section_for_product #row_for_text .for_product_small_text_none {
  display: none;
  margin-top: 20px;
  font-family: Montserrat arm;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 600px) {
  #section_for_product #row_for_text .for_product_small_text_none {
    display: none;
    font-size: 14px;
  }
}
#section_for_product .for_img_row {
  margin-top: 60px;
}
#section_for_product .for_img_row .div_for_png {
  overflow: hidden;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  #section_for_product .for_img_row .div_for_png {
    display: flex;
    justify-content: center;
    padding: 0 !important;
    margin-top: 0px !important;
  }
}
#section_for_product .for_img_row .div_for_png .for_product_image_hover {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 99999;
}
@media screen and (max-width: 600px) {
  #section_for_product .for_img_row .div_for_png .for_product_image_hover {
    padding: 0;
    width: fit-content;
  }
}
#section_for_product .for_img_row .div_for_png .for_product_image_hover .relative_div_img {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s linear;
  overflow: hidden;
}
#section_for_product .for_img_row .div_for_png .for_product_image_hover .relative_div_img img {
  transition: all 0.3s linear;
  filter: none;
}
#section_for_product .for_img_row .div_for_png .for_product_image_hover .relative_div_img img:hover {
  transform: scale(1.2);
}
#section_for_product .for_img_row .div_for_png .for_product_image_hover .absolute_div_text {
  position: absolute;
  height: auto;
  width: 100%;
  bottom: 0;
  background: #00386A;
  box-shadow: -2px 4px 6px #DADADA;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
@media screen and (max-width: 600px) {
  #section_for_product .for_img_row .div_for_png .for_product_image_hover .absolute_div_text {
    font-size: 14px;
  }
}
#section_for_product .for_see_more_Link {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  z-index: 9999999999999;
}
@media screen and (max-width: 600px) {
  #section_for_product .for_see_more_Link {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
#section_for_product .for_see_more_Link a {
  width: 171px;
  height: 43px;
  background: #DC6B33;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}

.setion_for_representation {
  padding-top: 80px;
  padding-bottom: 60px;
}
@media screen and (max-width: 600px) {
  .setion_for_representation {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}
.setion_for_representation .for_representation_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 600px) {
  .setion_for_representation .for_representation_title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #494949;
    margin-bottom: 20px;
  }
}
.setion_for_representation .row_for_carousel {
  padding-top: 40px;
  padding-bottom: 80px;
  /* card style end */
  /* carousel slider style start */
}
@media screen and (max-width: 600px) {
  .setion_for_representation .row_for_carousel {
    padding: 0;
    padding-top: 0px;
    padding-bottom: 40px;
  }
}
.setion_for_representation .row_for_carousel .slick-dots {
  margin-bottom: 0px !important;
}
.setion_for_representation .row_for_carousel .slick-prev {
  display: none !important;
}
.setion_for_representation .row_for_carousel .slick-next {
  display: none !important;
}
.setion_for_representation .row_for_carousel .img-card {
  border-radius: 4px;
  background: transparent;
  color: #666;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 160px;
  overflow: hidden;
  transition: 0.3s;
  cursor: pointer;
}
.setion_for_representation .row_for_carousel .slick-dots li {
  bottom: -40px !important;
}
@media screen and (max-width: 60px) {
  .setion_for_representation .row_for_carousel .slick-dots li {
    bottom: 0px !important;
  }
}
.setion_for_representation .row_for_carousel .slick-dots li button::before {
  color: #0F62AC;
}
.setion_for_representation .row_for_carousel .img-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.setion_for_representation .row_for_carousel .card-body {
  padding: 15px;
}
.setion_for_representation .row_for_carousel .card-title {
  font-size: 20px;
  font-weight: 600;
}
.setion_for_representation .row_for_carousel .card-text {
  margin-top: 10px;
}
.setion_for_representation .row_for_carousel .slick-slide > div {
  margin: 0 10px;
}
.setion_for_representation .row_for_carousel .slick-list {
  margin: 0 -10px;
}
.setion_for_representation .row_for_carousel .slick-slide *:focus {
  outline: none;
}

.slick-dots {
  position: absolute;
  bottom: 0 !important;
}

.setion_for_news {
  padding-top: 40px;
  position: relative;
  padding-bottom: 80px;
  border-bottom: 3px solid #F2F2F2;
  background: #F2F2F2;
}
@media screen and (max-width: 600px) {
  .setion_for_news {
    padding-top: 30px;
  }
}
.setion_for_news .for_news_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
  text-align: center;
  padding-bottom: 60px;
  padding-top: 20px;
}
@media screen and (max-width: 600px) {
  .setion_for_news .for_news_title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-align: center;
    color: #494949;
  }
}
@media screen and (max-width: 600px) {
  .setion_for_news .row_for_news .card-none {
    display: none;
  }
}
.setion_for_news .row_for_news .hover-card {
  background: transparent !important;
}
@media screen and (max-width: 600px) {
  .setion_for_news .row_for_news .hover-card {
    padding: 0;
  }
}
.setion_for_news .row_for_news .hover-card .card {
  height: 200px;
  border-radius: 15px;
  padding: 1.5rem;
  background: none !important;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  transition: 0.4s ease-out;
  cursor: pointer;
  border: none;
}
.setion_for_news .row_for_news .hover-card .card:hover {
  transform: translateY(10px);
}
.setion_for_news .row_for_news .hover-card .card:hover:before {
  opacity: 1;
}
.setion_for_news .row_for_news .hover-card .card:hover .info {
  opacity: 1;
  transform: translateY(0px);
}
.setion_for_news .row_for_news .hover-card .info .title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.015em;
  color: #fff;
}
.setion_for_news .row_for_news .hover-card .info a {
  color: #FFFFFF;
  text-decoration: none;
}
.setion_for_news .row_for_news .hover-card .card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}
.setion_for_news .row_for_news .hover-card .card img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}
.setion_for_news .row_for_news .hover-card .card .info {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
}
.setion_for_news .row_for_news .hover-card .card .info h1 {
  margin: 0px;
}
.setion_for_news .row_for_news .hover-card .card .info p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}
.setion_for_news .row_for_news .hover-card .card .info button {
  padding: 0.6rem;
  outline: none;
  border: none;
  border-radius: 3px;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease;
}
.setion_for_news .row_for_news .hover-card .card .info button:hover {
  background: dodgerblue;
  color: white;
}
.setion_for_news #link_for_see_more_small {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 171px;
  height: 43px;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  border: 1px solid #0F62AC;
  color: #0F62AC;
  margin: auto;
  margin-top: 82px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.setion_for_news #link_for_see_more_small:hover {
  background: #73B3F2 !important;
  color: #FFFFFF !important;
}
@media screen and (max-width: 576px) {
  .setion_for_news .flex_content {
    display: none;
  }
}

.section_for_partners {
  padding-top: 40px;
}
.section_for_partners .partners_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-align: center;
  color: #494949;
  padding: 20px 0;
}
.section_for_partners .row_for_carousele {
  padding-bottom: 80px;
  /* card style end */
  /* carousel slider style start */
}
@media screen and (max-width: 600px) {
  .section_for_partners .row_for_carousele {
    padding: 0;
    padding-top: 0px;
    padding-bottom: 40px;
  }
}
.section_for_partners .row_for_carousele .slick-prev {
  display: none !important;
}
.section_for_partners .row_for_carousele .slick-next {
  display: none !important;
}
.section_for_partners .row_for_carousele .img-card {
  border-radius: 4px;
  background: transparent;
  color: #666;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 200px;
  overflow: hidden;
  transition: 0.3s;
}
.section_for_partners .row_for_carousele .img-card img {
  max-width: 80%;
  max-height: 80%;
}
@media screen and (max-width: 600px) {
  .section_for_partners .row_for_carousele .img-card {
    height: 140px;
  }
}
.section_for_partners .row_for_carousele .slick-dots li {
  bottom: -40px !important;
}
@media screen and (max-width: 600px) {
  .section_for_partners .row_for_carousele .slick-dots li {
    bottom: -10px !important;
    top: 0;
  }
}
.section_for_partners .row_for_carousele .slick-dots li button::before {
  color: #0F62AC;
}
.section_for_partners .row_for_carousele .img-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.section_for_partners .row_for_carousele .card-body {
  padding: 15px;
}
.section_for_partners .row_for_carousele .card-title {
  font-size: 20px;
  font-weight: 600;
}
.section_for_partners .row_for_carousele .card-text {
  margin-top: 10px;
}
.section_for_partners .row_for_carousele .slick-slide > div {
  margin: 0 10px;
}
.section_for_partners .row_for_carousele .slick-list {
  margin: 0 -10px;
}
.section_for_partners .row_for_carousele .slick-slide *:focus {
  outline: none;
}

