@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.representation-header-content {
  background: #00386A;
  box-shadow: 0px 0px 12px #F2F2F2;
  margin-top: 40px;
  padding-right: 0;
}
@media screen and (max-width: 768px) {
  .representation-header-content {
    background: transparent;
    box-shadow: none;
    padding-left: 0;
  }
}
@media screen and (max-width: 578px) {
  .representation-header-content {
    background: transparent;
    box-shadow: none;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 992px) {
  .representation-header-content .right-content-img img {
    height: 100%;
  }
}
.representation-header-content .representation-text {
  padding: 0px 20px;
  margin-top: 66px;
}
@media screen and (max-width: 1200px) {
  .representation-header-content .representation-text {
    margin-top: 20px;
  }
}
@media screen and (max-width: 992px) {
  .representation-header-content .representation-text {
    height: 247px;
    overflow-y: scroll;
    padding-left: 10px;
  }
}
@media screen and (max-width: 992px) {
  .representation-header-content .representation-text {
    height: auto;
    overflow-y: visible;
    padding-left: 10px;
  }
}
@media screen and (max-width: 768px) {
  .representation-header-content .representation-text {
    background: #00386A;
    box-shadow: 0px 0px 12px #F2F2F2;
    padding: 20px 15px;
  }
}
.representation-header-content .representation-text .representation-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
@media screen and (max-width: 992px) {
  .representation-header-content .representation-text .representation-title {
    font-size: 16px;
    line-height: 20px;
  }
}
.representation-header-content .representation-text .representation-title-second {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
@media screen and (max-width: 992px) {
  .representation-header-content .representation-text .representation-title-second {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    padding: 0;
    text-align: justify;
  }
}
.representation-header-content .representation-text .representation-desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  padding-right: 20px;
  text-align: justify;
}
@media screen and (max-width: 992px) {
  .representation-header-content .representation-text .representation-desc {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    padding-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .for_computer_size {
    display: none;
  }
}
.for_computer_size .scrl-content {
  position: relative;
  z-index: 999999999;
}
.for_computer_size .left-button {
  position: absolute;
  top: 60px;
  left: 0px;
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  border-radius: 50%;
  z-index: 100000000000000020000000000;
  background: transparent;
}
.for_computer_size .left-button img {
  width: 30px;
  height: 30px;
  animation-name: imgSize1;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
@keyframes imgSize1 {
  from {
    transform: scale(1);
    opacity: 0.6;
  }
  to {
    transform: scale(1.2);
    opacity: 1;
  }
}
.for_computer_size .right-button {
  position: absolute;
  top: 60px;
  right: 0px;
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  border-radius: 50%;
  z-index: 1000000000000000100000;
  background: transparent;
}
.for_computer_size .right-button img {
  width: 30px;
  height: 30px;
  animation-name: imgSize;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
@keyframes imgSize {
  from {
    transform: scale(1);
    opacity: 0.6;
  }
  to {
    transform: scale(1.2);
    opacity: 1;
  }
}
.for_computer_size .full-width-desc {
  background: #F1F1F1;
  padding-top: 60px;
  padding-bottom: 60px;
}
.for_computer_size .full-width-desc .title-description {
  padding-left: 20px;
  padding-right: 20px;
}
.for_computer_size .full-width-desc .title-description .title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
}
.for_computer_size .full-width-desc .title-description .description {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #494949;
  margin-bottom: 0;
  text-align: justify;
}
.for_computer_size .full-width-desc .title-description .description-second {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin: 0;
  letter-spacing: -0.015em;
  color: #494949;
}
.for_computer_size .for_tab_first {
  margin-top: 60px;
  padding-top: 70px;
  position: relative;
}
.for_computer_size .for_tab_first .for_tab_first_title_dsc {
  padding-left: 20px;
  padding-right: 20px;
}
.for_computer_size .for_tab_first .for_tab_first_title_dsc .tab_ul_desc {
  padding-left: 28px;
  margin-top: 20px !important;
}
.for_computer_size .for_tab_first .for_tab_first_title_dsc .tab_ul_desc li {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.015em;
}
.for_computer_size .for_tab_first .for_tab_first_title_dsc .for_tab_desc_first {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
  margin-top: 40px;
}
.for_computer_size .for_tab_first .for_tab_first_title_dsc .for_tab_desc_second {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
  margin-top: 20px;
}
.for_computer_size .for_tab_first .for_tab1_image {
  position: relative;
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .for_computer_size .for_tab_first .for_tab1_image {
    margin-top: 40px;
  }
}
.for_computer_size .for_tab_first_fluid {
  margin-top: 60px;
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
  background: #F7F7F7;
}
.for_computer_size .for_tab_first_fluid .for_tab_first_title_dsc {
  padding-left: 86px;
  padding-right: 20px;
}
.for_computer_size .for_tab_first_fluid .for_tab_first_title_dsc .tab_ul_desc {
  padding-left: 28px;
  margin-top: 20px !important;
}
.for_computer_size .for_tab_first_fluid .for_tab_first_title_dsc .tab_ul_desc li {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.015em;
}
.for_computer_size .for_tab_first_fluid .for_tab_first_title_dsc .for_tab_desc_first {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
  margin-top: 40px;
}
.for_computer_size .for_tab_first_fluid .for_tab_first_title_dsc .for_tab_desc_second {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
  margin-top: 20px;
}
.for_computer_size .for_tab_first_fluid .for_tab1_image {
  position: relative;
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .for_computer_size .for_tab_first_fluid .for_tab1_image {
    margin-top: 40px;
  }
}

#for-select-selected {
  position: relative;
}
#for-select-selected .react-tabs__tab-list {
  background: transparent !important;
  border: none;
  margin-bottom: 0;
  padding-left: 0;
}
#for-select-selected .react-tabs__tab-list .react-tabs__tab {
  width: 25% !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  transition: all 0.3s linear;
}
#for-select-selected .react-tabs__tab-list li {
  display: inline-block !important;
  height: 149px;
  padding: 0;
}
#for-select-selected .react-tabs__tab-list li .flex_center_align {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#for-select-selected .react-tabs__tab-list li .flex_center_align img {
  max-width: 80%;
  max-height: 60px;
}
#for-select-selected .react-tabs__tab-list .react-tabs__tab--selected {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px 1px #DADADA;
  border: none !important;
  outline: none !important;
}

@media screen and (max-width: 778px) {
  #for-mobile-click {
    display: block;
  }
}
@media screen and (min-width: 778px) {
  #for-mobile-click {
    display: none;
  }
}
#for-mobile-click .row_for_brands_modal {
  padding-bottom: 60px;
  padding-left: 34px;
  padding-right: 34px;
}
@media screen and (max-width: 578px) {
  #for-mobile-click .row_for_brands_modal {
    padding-left: 0;
    padding-right: 0;
  }
}
#for-mobile-click .row_for_brands_modal .mobile_brand_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px solid #dadada;
  padding-top: 20px;
  padding: 20px 5px;
}

.modal-body {
  padding-top: 0;
}
.modal-body .for_tab_first_title_dsc {
  margin-top: 20px;
}

#mobile-title-brand {
  background: #F1F1F1;
  padding-top: 60px;
  padding-bottom: 30px;
}
#mobile-title-brand .mobile-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #494949;
}
#mobile-title-brand .mobile-desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #494949;
  text-align: justify;
  margin-top: 20px;
}

.base_section_for_brand_mobile_size {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #C4C4C4;
}
.base_section_for_brand_mobile_size .tab_ul_desc {
  padding-left: 14px;
  margin-top: 20px !important;
}
.base_section_for_brand_mobile_size .tab_ul_desc li {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: -0.015em;
}
.base_section_for_brand_mobile_size .second_base_row {
  padding-bottom: 30px;
}
.base_section_for_brand_mobile_size .second_base_row .base-title {
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #494949;
}
.base_section_for_brand_mobile_size .second_base_row .base-desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #494949;
}

#small_icon_for_modal {
  transform: scale(0.8);
  margin-top: 20px;
}

