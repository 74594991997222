@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$fontFamily:'Montserrat' , sans-serif;

.about_us_big_fluid{
  position:relative;
  padding-top: 40px;
  padding-bottom:40px;
  .first_section_about_us{
    position: relative;
    .first_section_left_box{
      padding: 30px 80px 30px 30px;
      box-shadow: 4px 5px 12px #DADADA;
      background: #F2F2F2;

      @media screen and (max-width: 1200px){
        padding: 20px 20px 20px 20px;
      }@media screen and (max-width: 992px){
        padding: 20px 20px 20px 20px;
        overflow-y: auto;

    }
      @media screen and (max-width: 778px){
        padding: 30px 15px 0px 15px;
      }
      .section_for_title_desc{
        @media screen and (max-width: 1200px){
          height: 344px;
          //background: red;
          //overflow-y: scroll;
        }
        @media screen and (max-width: 992px){
          height: fit-content;
          height: 300px;
        }
        .first_big_title{
          font-family:$fontFamily;
          font-style: normal;
          font-size: 18px;
          font-weight: bold;
          line-height: 22px;
          letter-spacing: -0.015em;
          color: #494949;
          @media screen and (max-width: 992px){
            font-family: $fontFamily;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.015em;
            color: #494949;
          }
        }
        .second_big_title{
          font-family:$fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.015em;
          color: #494949;
          @media screen and (max-width: 992px){
            font-family:$fontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.015em;
            color: #494949;
          }
        }
        .first_desc{
          font-family:$fontFamily;
          font-style: normal;
          //font-weight: normal;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: -0.015em;
          color: #AAA9A9;
          @media screen and (max-width: 992px){
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.015em;
            color: #494949;
          }
        }
        .second_desc{
          font-family: $fontFamily;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.015em;
          color: #AAA9A9;
          @media screen and (max-width: 992px){
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.015em;
            color: #494949;
          }
        }
      }
    }
    .first_section_right_box{
      position: relative;
      z-index: 999999999999;
      padding-right: 0;
      padding-left: 30px;
      @media screen and (max-width: 778px){
        padding-left: 0;
        margin-top: 30px;
      }
      .first_section_right_box_img{
        padding-right: 0px;
        height: 100%;
        //@media screen {
        //
        //}
        @media screen and (max-width: 778px){
          padding-left: 0;
        }
        img{
          height: 100%;
        }
      }
    }
  }
}
.second_section_about_us_fluid{
  position: relative;
  padding-top: 40px;
  padding-bottom:60px;
  .second_section_about_us{
    position: relative;
    .mobile-size-none{
      display: block;
      @media screen and (max-width: 768px){
        display: none;
      }

    }
    .mobile-size-block{
      display: none;
      @media screen and (max-width: 768px){
        display: block;
        padding-right: 0;
      }

    }
    .left-content{
      position: relative;
      z-index: 999999999999;
    }
    .col-for-left-img{
      padding-left: 0;
    }
    .col-for-right-text{
      background: #F2F2F2;
      box-shadow: 4px 5px 12px #DADADA;

      .second_section_for_title_desc{
        padding: 20px 60px 20px 40px;
        @media screen and (max-width: 1200px){
          overflow-y: scroll;
          height: 374px;
        }
        @media screen and (max-width: 992px){
          overflow-y: scroll;
          height: 277px;
        }
        @media screen and (max-width: 778px){
          padding: 20px 20px 20px 20px;
          height: 277px;
        }


        .first_big_title{
          font-family:$fontFamily;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.015em;
          color: #494949;
          margin-top: 20px;
          @media screen and (max-width: 992px){
            font-family:$fontFamily;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.015em;
            color: #494949;
          }
        }
        .second_big_title{
          margin-top: 10px;

          font-family:$fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.015em;
          color: #494949;
          @media screen and (max-width: 992px){
            font-family:$fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.015em;
            color: #494949;
          }
        }
        .first_desc{
            ul{
              padding: 0;
                li{
                  font-family: $fontFamily;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 20px;
                  letter-spacing: -0.015em;
                  color: #494949;
                  margin-top: 10px;
                  @media screen and (max-width: 992px){
                    font-family:$fontFamily;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: -0.015em;
                    color: #494949;
                  }
                }
            }

        }
        .second_desc{
          font-family: $fontFamily;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.015em;
          color: #AAA9A9;
          @media screen and (max-width: 992px){
            font-family:$fontFamily;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.015em;
            color: #494949;
          }
        }

      }
    }

  }
}

.for_new_brands{
  margin-top: 0px;
  background: #00386A;
  //box-shadow: 4px 5px 12px #DADADA;
  padding-right: 0;
  @media screen and (max-width: 576px){
    background: #ffffff;
    padding-left: 30px;
    padding-right: 30px;
  }
  .left_content{
    display: flex;
    align-items: center;
    padding: 40px 80px 40px 80px;
    @media screen and (max-width: 992px){
      padding: 40px 40px 40px 40px;
    }
    @media screen and (max-width: 768px){
      padding: 20px 10px;
    }
    @media screen and (max-width: 578px){
      padding: 20px 30px 20px 30px;
      background: #00386A;
    }
    #brand-link{
      background: #FFFFFF;
      border: 1px solid #DADADA;
      box-shadow: -2px 4px 4px #DADADA;
      border-radius: 20px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.015em;
      color: #00386A;
      padding: 10px 40px;
      position: relative;
      top: 30px !important;
    }
    .section_for_title_desc{
      .brand-title{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bolder;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        @media screen and (max-width: 768px){
          font-family: $fontFamily;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
        }
      }
      .brand-desc-bold{
        font-family:$fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 0;
        @media screen and (max-width: 768px){
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
        }
      }
      .brand-desc{
        font-family:$fontFamily;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        margin: 0;
        @media screen and (max-width: 768px){
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
          margin-top: 10px;
        }
      }

    }
  }
  .right_content{
    padding-left: 0;
    @media screen and (max-width: 576px){
      padding-right: 0;
    }
    img{
      height: 100% !important;
    }
  }
}
.for_new_service{
  margin-top: 60px;
  margin-bottom: 100px;
  padding-left: 0;
  padding-right: 0;
  background: #0F62AC;
  box-shadow: 4px 5px 12px #DADADA;
  @media screen and (max-width: 578px){
    background: #fff;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .mobile-size-none{
    display: block;
    @media screen and (max-width: 578px){
      display: none;
    }
  }
  .mobile-size-block{
    display: none;
    @media screen and (max-width: 578px){
      display: block;
      padding-left: 0;
    }
  }

  .left_content{
    display: flex;
    align-items: center;
    padding: 40px 80px 40px 80px;
    @media screen and (max-width: 992px){
      padding: 40px 40px 40px 40px;
    }
    @media screen and (max-width: 768px){
      padding: 20px;
    }
    @media screen and (max-width: 578px){
      padding: 20px 30px 20px 30px;
      background: #00386A;
    }
    #brand-link{
      background: #FFFFFF;
      border: 1px solid #DADADA;
      box-shadow: -2px 4px 4px #DADADA;
      border-radius: 20px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.015em;
      color: #00386A;
      padding: 10px 40px;
      position: relative;
      top: 30px !important;
    }
    .section_for_title_desc{
      .brand-title{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bolder;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        @media screen and (max-width: 768px){
          font-size: 16px;
          line-height: 20px;
        }
      }
      .brand-desc-bold{
        font-family:$fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 0;
        @media screen and (max-width: 768px){
          font-weight: 400;
          font-size: 13px;
          line-height: 17px;
        }
      }
      .brand-desc{
        font-family:$fontFamily;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        @media screen and (max-width: 768px){
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          margin-top: 10px;
        }
      }

    }
  }
  .right_content{
    padding-right: 0;
    img{
      height: 100% !important;
    }
  }
}
.for_link_see{
  margin-top: 60px;
  @media screen and (max-width: 768px){
    margin-top: 30px;
  }

  a{
    background: #FFFFFF;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 14.5px 40px 14.5px 40px;
    font-family:$fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #0F62AC;
    transition: all .5s linear;
    &:hover{
      background: #73B3F2 !important;
      color: #FFFFFF !important;
    }
    @media screen and (max-width: 768px){
      padding: 6px 15px 6px 15px;
    }
  }
}
//brands


.first-brand-fluid-section{
  background: #f1f1f1;
  padding-top: 40px;
  padding-bottom:20px;
  .section_for_brands{
    .brands_title{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: bolder;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #494949;
      @media screen and (max-width: 600px){
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.015em;

        color: #494949;
      }
    }
    .brands-desc{
      //padding: 0;
      margin-top: 30px;
      font-family:$fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #494949;
      @media screen and (max-width: 600px){
        font-family: $fontFamily;
        font-style: normal;
        font-weight:400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: -0.015em;

        color: #494949;
      }
    }


  }

}


.section_for_br_tab{
  background: #F1F1F1;
  padding-bottom: 50px;
  .brands-tab-list{
    padding-top: 30px;

    @media screen and (max-width: 600px){
      display: none;
    }
    .react-tabs__tab-list{
      display: flex !important;
      justify-content: space-between !important;
      margin-top: 20px !important;
      background: transparent !important;
      border-bottom:none;
      padding: 0 !important;
      @media screen and (max-width:576px){
        justify-content: space-between;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .react-tabs__tab{

        background: #FFFFFF;
        box-shadow: -1px 4px 9px 1px #C4C4C4;
        border-radius: 10px;
        width: 230px !important;
        font-family:'Montserrat' , sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #494949;
        padding:0px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 109px;
        border: none;
        cursor: pointer;
        @media screen and (max-width: 768px){
          height: 80px;
        }
        img{
          height: 80px;
          @media screen and (max-width: 768px){
            height: 66px;
          }
        }
        &:nth-child(2){
          img{
            height: 74px;
            @media screen and (max-width: 768px){
              height: 60px;
            }
          }

        }
        &:nth-child(3){
          img{
            height: 74px;

            @media screen and (max-width: 768px){
              height: 60px;
            }
          }
        }
        @media screen and (max-width: 992px){
          width:190px !important;
        }
        @media screen and (max-width: 768px){
          width:160px !important;
        }
        @media screen and (max-width: 600px) {
          width: 160px !important;
          height:69px;
          background: #FFFFFF;
          box-shadow: 3px 5px 17px #DADADA;
          border-radius: 10px;
          font-family:'Montserrat' , sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: -0.015em;
          color: #494949;
          margin-left: 0;
        }
        @media screen and (max-width: 576px){
          background: #FFFFFF;
          box-shadow: 8px 16px 21px #DADADA;
          border-radius: 10px;
          width:156px;
          height:48.15px;
          margin-top: 23px;
          border: none;
        }

      }
      #react-tabs-1{
        padding-bottom: 0px  !important;

      }
      .react-tabs__tab--selected{
        background: #F2F2F2;
        box-shadow: -1px 5px 9px 1px #C4C4C4;
        border-radius: 10px;
        padding-bottom: 0px !important;
        font-family:'Montserrat' , sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #FEFEFE;
        p{
          color: #FFFFFF;
        }
        @media screen and (max-width: 600px) {
          font-family:'Montserrat' , sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
        }
        @media screen and (max-width: 576px){
          background: #0F62AC;
          box-shadow: 8px 16px 21px #DADADA;
          border-radius: 10px;
          border: none;
          color: #FFFFFF;
        }
      }
    }
  }
  .brands-tab-list-mobile{
    display: none;

    @media screen and (max-width: 600px){
      display: block;
      padding-top: 20px;
    }
    .react-tabs__tab-list{
      display: flex !important;
      justify-content:space-evenly;
      margin: 0;
      background: transparent !important;
      border-bottom:none;
      flex-direction: column;
      align-items: center !important;
      padding-left: 0;
      .react-tabs__tab{
        cursor: pointer;
        background: #FFFFFF !important;
        box-shadow: 8px 16px 21px #DADADA;
        border-radius: 10px;
        width:156px;
        height:48.15px;
        margin:auto;
        margin-top: 23px !important;
        border:none;
        display:flex;
        justify-content:center;
        align-items: center;
        font-family:$fontFamily;
        font-weight: bold;
        font-size: 18px;
        font-style: normal;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #494949;
        &:nth-child(1){
          margin-top: 13px !important;
        }
        &:nth-child(3){
          margin-bottom: 13px !important;
        }
      }
      .react-tabs__tab--selected{
        background: #0F62AC !important;
        box-shadow: 8px 16px 21px #DADADA;
        border-radius: 10px;
        border: none;
        font-family:$fontFamily;
        font-weight: bold;
        font-style: normal;
        line-height: 20px;
        font-size: 16px;
        align-items: center;
        display: flex;
        letter-spacing: -0.015em;
        text-align: center;
        color: #FFFFFF;
      }
    }

  }
}
.for_icons{
  background: #0F62AC;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 30px;
  @media screen and (max-width:600px){
    border-right: 10px solid #FFFFFF;
    border-left: 10px solid #FFFFFF;
    box-sizing: border-box;
  }
  @media screen and (max-width:578px){
    border-right: 10px solid #FFFFFF;
    border-left: 10px solid #FFFFFF;
    box-sizing: border-box;
  }
  .for_slider_icon{
    .left-content{
      padding-left: 0;
      @media screen and (max-width:768px) {

      }
      .left-title{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        @media screen and (max-width:768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .left-desc{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        text-align: justify;
        @media screen and (max-width:768px) {
          font-size: 16px;
          line-height: 20px;
        }
        @media screen and (max-width:600px) {
          font-size: 16px;
          line-height: 20px;
          text-align: center;
        }
      }
    }
    .flex-content-for-circle{
      display: flex;
      align-items: center;
      padding-left: 0;
      @media screen and (max-width:768px){
        padding-left: 30px;
        margin-top: 20px;
      }
      @media screen and (max-width:576px){
        padding-left: 40px;
      }
      @media screen and (max-width:500px){
        padding-left: 20px !important;
        padding-right: 50px !important;
      }
      @media screen and (max-width:440px){
        padding-left: 30px !important;
        padding-right: 40px !important;
      }
      .slider_icon_circle_div{
        border: 1px solid #fff;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width:768px){
          margin-top: 10px;
        }
        img{
          width:34.59px;
          height:29.53px;
        }
      }
    }

  }
}
.brand-tab-cont{
  padding-top: 40px;
  padding-bottom: 40px;

  .brand_slider_title_desc{
    padding: 0px 80px 0px 80px;
    .brand_slider_title{
      img{
        width: 163px;
        height:109px;
      }
      .vita-logo{
        width: 170px;
        height: 74px;
      }
      @media screen and (max-width:576px){
        display: flex;
        justify-content: center;
      }
    }
    @media screen and (max-width:992px){
      padding: 20px 20px 20px 20px;
      height: 351px;
      overflow-y: scroll;
    }
    @media screen and (max-width:768px){
      padding: 20px 5px;
      height: 262.3px;
      overflow-y: scroll;

    }
    @media screen and (max-width:600px){
      // padding: 20px 20px 20px 20px;
    }
    @media screen and (max-width:576px){
      padding: 20px 80px;
      height:auto;
      overflow-y: visible;

    }
    @media screen and (max-width:500px){
      padding: 20px 40px;
    }
    @media screen and (max-width:375px){
      padding: 20px 20px;
    }

    .brand_slider_desc_first{
      font-family:$fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #494949;
      @media screen and (max-width:992px){
        font-size: 16px;
        line-height: 20px;
      }
      @media screen and (max-width:576px){
        font-size: 16px;
        line-height: 20px;
        margin-top: 20px;
      }
    }
    .brand_slider_desc_second{
      font-family:$fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #494949;
      text-align: justify;
      margin-top: 40px;
      @media screen and (max-width:992px){
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .content-for-img{
    padding-left: 0;
    padding-right: 0;
    @media screen and (max-width:576px){
      display: flex;
      justify-content: center;
    }
    @media screen and (max-width:576px){
      padding: 0px 15px;
    }
    img{
      //height: 100%;
      @media screen and (max-width:992px){
        height: auto;
      }
    }
  }
}

// Service

.first-section-for-serice{
  padding-top: 40px;
  .service-title{
    font-family:$fontFamily;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #494949;
  }
  .service-desc{
    padding: 0px 10px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #494949;
  }
}
.second-section-for-serice{
  margin-top: 60px;
  background: #F1F1F1;
  box-shadow: 4px 5px 12px #DADADA;

  @media screen and (max-width:600px){
    border-right: 15px solid #FFFFFF;
    border-left: 15px solid #FFFFFF;
    box-sizing: border-box;

  }

  .left-content-img{
    background: #fff;
    padding-right: 0;
    padding-left: 0;

    //@media screen and (max-width:600px){
    //  padding-right: 0;
    //  padding-left: 0;
    //  height: 202px;
    //  overflow: hidden;
    //}
    //img{
    //  @media screen and (max-width:600px){
    //    height: 202px;
    //  }
    //  @media screen and (max-width:576px){
    //    height: 202px;
    //    width: 100%;
    //  }
    //}
  }
  .right-content-text{
    padding-left: 50px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    height: 100%;
    @media screen and (max-width:992px){
      height: 202px;
      overflow-y: scroll;
      padding-top: 140px;
    }
    @media screen and (max-width:768px){
      height: 202px;
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 10px;
      align-items: flex-start !important;
      justify-content: flex-start !important;
    }
    @media screen and (max-width:600px){
      height: 202px !important;
      align-items: flex-start !important;
      justify-content: flex-start !important;

    }
    @media screen and (max-width:576px){
      height: auto !important;
      overflow-y: visible;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .product-title{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #494949;
      text-align: justify;
      @media screen and (max-width:768px){
        font-size: 16px;
        line-height: 20px;

      }
    }
    .product-desc{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #494949;
      text-align: justify;
      @media screen and (max-width:768px){
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}
.third-section-for-serice{
  box-shadow: 0px 6px -17px 4px #DADADA;
  background: transparent;
  margin-top: 40px;
  .Big_text_content{
    padding-left: 0;
    padding-right: 0;
    @media screen and (max-width:600px){
      padding-left: 15px;
      padding-right: 15px;

    }
  }
  .mobile-block{
    display: none;

    @media screen and (max-width:576px){
      display: block;
      img{
        width: 100%;
        height: 202px;
      }

    }
  }
  .mobile-none{
    display: block;
    @media screen and (max-width:576px){
      display: none;
    }

  }
  .right-content-text{
    background: #0F62AC;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 120px;
    @media screen and (max-width:992px){
      height: 330px;
      overflow-y: scroll;
      justify-content:flex-start !important;
      flex-direction: column;
      padding-top: 30px;
    }
    @media screen and (max-width:767px){
      height: 247px;
      overflow-y: scroll;
      justify-content:flex-start !important;
      flex-direction: column;
      padding-top: 30px;
      padding-right: 15px;
      padding-left: 15px;
    }
    @media screen and (max-width:576px){
      height: auto !important;
      overflow-y: visible;
      padding-right: 30px;
      padding-left: 30px;
    }
    .product-title{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #FFFFFF;
      @media screen and (max-width:767px){
        font-size: 16px;
        line-height: 20px;
      }
    }
    .product-desc{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #FFFFFF;
      text-align: justify;
      @media screen and (max-width:767px){
        font-size: 14px;
        line-height: 19px;
      }
    }
    ul{
      list-style: none;
      padding-left: 0;

      li{
        font-family:$fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        margin-top: 4px;
        @media screen and (max-width:767px){
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
  .img-content{
    padding: 0;
    @media screen and (max-width:576px){
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}
.forth-section-for-serice{
  margin-top: 60px;
  background: #F1F1F1;
  .left-content-img{
    background: #fff;
    padding-right: 0;
    @media screen and (max-width:576px){
      padding-right: 15px;

    }
    img{
      @media screen and (max-width:576px){
        width: 100%;
        height: 299px;
      }
    }

  }
  .right-contect-fluid{
    @media screen and (max-width:576px){
      border-left: 15px solid #FFFFFF;
      border-right: 15px solid #FFFFFF;
      box-sizing: border-box;
    }
  }
  .right-content-text{
    padding-left: 50px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    height: 100%;
    @media screen and (max-width:992px){
      padding: 20px;
      height:269px;
      overflow-y: scroll;
      justify-content: flex-start !important;
    }
    @media screen and (max-width:768px){
      padding: 20px;
      height:199px;
      overflow-y: scroll;
      justify-content: flex-start !important;
    }
    @media screen and (max-width:576px){
      padding: 20px;
      height:auto;
      overflow-y: visible;
      justify-content: flex-start !important;
      // border-right: 15px solid #FFFFFF;
      // border-left: 15px solid #FFFFFF;
      box-sizing: border-box;
    }
    .product-title{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #494949;
      @media screen and (max-width:768px){
        font-size: 16px;
        line-height: 20px;
      }
    }
    .product-desc{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #494949;
      text-align: justify;
      @media screen and (max-width:768px){
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}
.end-prew{
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  a{
    width: 171px;
    height: 43px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    box-shadow: -2px 4px 4px #DADADA;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    font-family:$fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #494949;
    transition: all .5s linear;
    &:hover{
      background: #73B3F2 !important;
      color: #FFFFFF !important;
    }
  }
}