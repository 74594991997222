@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$fontFamily:'Montserrat' , sans-serif;

.fluid_for_partners{
  margin-top: 40px;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  @media screen and (max-width: 768px) {
    padding-top: 10px;
    padding-bottom:40px;
    padding-left: 15px;
    padding-right: 15px;
  }


  .left_col_partners_text{
    background: #0F62AC;
    box-shadow: 0px 0px 12px #F2F2F2;
    @media screen and (max-width:1200px){
      //height: 288px;
      //overflow-y: scroll;
    }
    @media screen and (max-width: 768px) {
      height: auto;
      overflow-y: visible;
    }
    // @media screen and (max-width: 768px) {
    //   display:none;
    // }

    .partners_title_description{
      padding-right: 0;
      padding: 40px 40px;;
      @media screen and (max-width:1200px){
        padding: 40px 10px;
      }
      @media screen and (max-width:992px){
        padding: 10px 10px;
      }

      .partners_title{
        font-family:$fontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        margin-top: 40px;
        @media screen and (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .partners_desc_first{
        font-family:$fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        //padding-right: 30px;
        margin-top: 6px;
        text-align: justify;
        //margin-top: 40px;
        @media screen and (max-width: 768px) {
          font-size: 14px;
          line-height: 17px;
        }

      }
      .partners_desc_second{
        font-family:$fontFamily;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        margin-top: 6px;
        padding-right: 30px;
        @media screen and (max-width: 768px) {
          font-size: 14px;
          line-height: 17px;
          font-weight: 300;
        }
      }
      .partners_desc_third{
        font-family:$fontFamily;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        margin-top: 6px;
        padding-right: 30px;
        @media screen and (max-width: 768px) {
          font-size: 14px;
          line-height: 17px;
          font-weight: 300;
        }
      }
    }
  }
  .left_col_partners_text_for_mobile{
    display: none;
    @media screen and (max-width: 768px) {
      display:block;
    }

    .partners_title_description{
      .partners_title{
        font-family: Montserrat arm;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.015em;
        color: #494949;
        @media screen and (max-width: 768px) {
          font-family: Montserrat arm;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.015em;
          color: #494949;
          margin-top:40px;
        }
      }
      .partners_desc_first{
        font-family: Montserrat arm;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #494949;
        padding-right: 60px;
        @media screen and (max-width: 768px) {
          font-family: Montserrat arm;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.015em;
          color: #494949;
          padding-right: 10px;

        }
      }
      .partners_desc_second{
        font-family: Montserrat arm;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #494949;
        padding-right: 60px;
        @media screen and (max-width: 768px) {
          font-family: Montserrat arm;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.015em;
          padding-right: 20px;
          color: #494949;
        }
      }
      .partners_desc_third{
        font-family: Montserrat arm;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #494949;
        padding-right: 60px;
      }
    }
  }


  .right_col_partners_img{
    padding-right: 0;
    padding-left: 0;

    @media screen and (max-width: 768px){
      position:relative;
    }

    img{
      //width: 100%;
      @media screen and (max-width: 992px){
        height: 288px;
      }
    }
  }
}


.for_partners_logoS{
  padding-top: 60px;
  padding-bottom:60px;
  .logo_col{
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    //@media screen and (max-width: 768px){
      img{
        width: 60%;
        max-width: 110px;
        max-height: 110px;
      }
    //}
  }
  .small-icon{
    img{
      transform: scale(.7);
    }
  }
}




