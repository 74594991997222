@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$fontFamily:'Montserrat' , sans-serif;
select{
  border: none !important;
  outline: none !important;
  option:nth-child(1){
    //background: red;
  }
}
#section_for_carousel{
  @media screen and (max-width:1200px){
    margin-top: 40px;
  }   @media screen and (max-width:576px){
  margin-top: 0;
}
  #padding_none{
    padding: 0;
    @media screen and (max-width:576px){
      padding-right: 0;
      padding-left: 0;
    }
  }

  img{
    @media screen and (max-width: 500px){
      height: 146px !important;
    }
    @media screen and (max-width: 414px){
      height: 121px !important;
    }
    @media screen and (max-width: 375px){
      height: 110px !important;
    }
    @media screen and (max-width: 320px){
      height: 93px !important;
    }
  }

  .carousel-control-prev{
    display: none;
  }
  .carousel-control-next{
    display: none;
  }
  .carousel-indicators{
    bottom: -50px !important;
    li{
      width: 10px !important;
      height: 10px !important;
      border-radius: 50%;
      background: #C4C4C4;
      border: none;
    }
  }
}
.MuiSelect-root , .MuiSelect-select , .MuiSelect-selectMenu , .MuiInputBase-input , .MuiInput-input {
  img{
    position: relative !important;
    z-index: 0 !important;
  }
}
.MuiList-root , .MuiMenu-list , .MuiList-padding{
  //margin-left: 50px !important;

  li{
   position: relative;
   z-index: 99999999999999 !important;
 }
}
