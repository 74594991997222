@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.news-first-section {
  margin-top: 40px;
  box-shadow: 0px 0px 13px 1px #dadada;
  background: #F1F1F1;
  padding-top: 30px;
  padding-bottom: 30px;
}
.news-first-section .news-left-section {
  padding-right: 0;
}
@media screen and (max-width: 992px) {
  .news-first-section .news-left-section {
    display: flex;
    align-items: center;
    padding-right: 15px;
  }
}
@media screen and (max-width: 778px) {
  .news-first-section .right-content {
    margin-top: 20px;
  }
}
.news-first-section .right-content .right-content-text {
  padding-right: 10px;
}
.news-first-section .right-content .right-content-text .news-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 31px;
  letter-spacing: -0.015em;
  color: #00386A;
  padding-bottom: 14px;
}
@media screen and (max-width: 992px) {
  .news-first-section .right-content .right-content-text .news-title {
    font-size: 20px;
    line-height: 23px;
  }
}
@media screen and (max-width: 600px) {
  .news-first-section .right-content .right-content-text .news-title {
    font-size: 18px;
    line-height: 23px;
  }
}
.news-first-section .right-content .right-content-text .news-pre_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.015em;
  color: #494949;
  margin-top: 15px;
}
.news-first-section .right-content .right-content-text .news-desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
  margin-top: 6px;
  text-align: justify;
}
@media screen and (max-width: 992px) {
  .news-first-section .right-content .right-content-text .news-desc {
    font-size: 13px;
    line-height: 20px;
    margin-top: 0;
  }
}

