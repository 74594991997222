@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.fluid_for_partners {
  margin-top: 40px;
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 768px) {
  .fluid_for_partners {
    padding-top: 10px;
    padding-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.fluid_for_partners .left_col_partners_text {
  background: #0F62AC;
  box-shadow: 0px 0px 12px #F2F2F2;
}
@media screen and (max-width: 768px) {
  .fluid_for_partners .left_col_partners_text {
    height: auto;
    overflow-y: visible;
  }
}
.fluid_for_partners .left_col_partners_text .partners_title_description {
  padding-right: 0;
  padding: 40px 40px;
}
@media screen and (max-width: 1200px) {
  .fluid_for_partners .left_col_partners_text .partners_title_description {
    padding: 40px 10px;
  }
}
@media screen and (max-width: 992px) {
  .fluid_for_partners .left_col_partners_text .partners_title_description {
    padding: 10px 10px;
  }
}
.fluid_for_partners .left_col_partners_text .partners_title_description .partners_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .fluid_for_partners .left_col_partners_text .partners_title_description .partners_title {
    font-size: 16px;
    line-height: 20px;
  }
}
.fluid_for_partners .left_col_partners_text .partners_title_description .partners_desc_first {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  margin-top: 6px;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .fluid_for_partners .left_col_partners_text .partners_title_description .partners_desc_first {
    font-size: 14px;
    line-height: 17px;
  }
}
.fluid_for_partners .left_col_partners_text .partners_title_description .partners_desc_second {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  margin-top: 6px;
  padding-right: 30px;
}
@media screen and (max-width: 768px) {
  .fluid_for_partners .left_col_partners_text .partners_title_description .partners_desc_second {
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
  }
}
.fluid_for_partners .left_col_partners_text .partners_title_description .partners_desc_third {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  margin-top: 6px;
  padding-right: 30px;
}
@media screen and (max-width: 768px) {
  .fluid_for_partners .left_col_partners_text .partners_title_description .partners_desc_third {
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
  }
}
.fluid_for_partners .left_col_partners_text_for_mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .fluid_for_partners .left_col_partners_text_for_mobile {
    display: block;
  }
}
.fluid_for_partners .left_col_partners_text_for_mobile .partners_title_description .partners_title {
  font-family: Montserrat arm;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.015em;
  color: #494949;
}
@media screen and (max-width: 768px) {
  .fluid_for_partners .left_col_partners_text_for_mobile .partners_title_description .partners_title {
    font-family: Montserrat arm;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.015em;
    color: #494949;
    margin-top: 40px;
  }
}
.fluid_for_partners .left_col_partners_text_for_mobile .partners_title_description .partners_desc_first {
  font-family: Montserrat arm;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
  padding-right: 60px;
}
@media screen and (max-width: 768px) {
  .fluid_for_partners .left_col_partners_text_for_mobile .partners_title_description .partners_desc_first {
    font-family: Montserrat arm;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #494949;
    padding-right: 10px;
  }
}
.fluid_for_partners .left_col_partners_text_for_mobile .partners_title_description .partners_desc_second {
  font-family: Montserrat arm;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
  padding-right: 60px;
}
@media screen and (max-width: 768px) {
  .fluid_for_partners .left_col_partners_text_for_mobile .partners_title_description .partners_desc_second {
    font-family: Montserrat arm;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    padding-right: 20px;
    color: #494949;
  }
}
.fluid_for_partners .left_col_partners_text_for_mobile .partners_title_description .partners_desc_third {
  font-family: Montserrat arm;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #494949;
  padding-right: 60px;
}
.fluid_for_partners .right_col_partners_img {
  padding-right: 0;
  padding-left: 0;
}
@media screen and (max-width: 768px) {
  .fluid_for_partners .right_col_partners_img {
    position: relative;
  }
}
@media screen and (max-width: 992px) {
  .fluid_for_partners .right_col_partners_img img {
    height: 288px;
  }
}

.for_partners_logoS {
  padding-top: 60px;
  padding-bottom: 60px;
}
.for_partners_logoS .logo_col {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.for_partners_logoS .logo_col img {
  width: 60%;
  max-width: 110px;
  max-height: 110px;
}
.for_partners_logoS .small-icon img {
  transform: scale(0.7);
}

